import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Switch, Route, Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Toaster, Position, Callout, Intent, AnchorButton } from '@blueprintjs/core';
import { decodeToken } from 'utilities';
import { MasqueradeService } from 'services/MasqueradeService';
import { RespondService } from 'services/RespondService';
import GlobalStyle from '../../global-styles';
import { MODAL_TO_SHOW } from 'utilities/constants';
import { Sidebar } from './Sidebar';
import { UsersService } from '../../services/UsersService';
import selectRoute from './selectors';
import { routes } from './routes';
import MenuContext from '../../context/MenuContext';
import { UserService } from 'services';
import './index.scss';
import './responsive.scss';
import './Users/masquerade.css';
import { checkPermission } from 'utilities/permissions';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { resetState } from 'routeReducer';
import { ToasterNotification, ToasterContext, Button, LoadingModal } from 'componentsV2';
import { ActivateAlarmModal } from '../RapidAlarms/Modals/ActivateAlarmModal';

class App extends React.Component {
	isMasquerading = false;

	isResponderMasquerading = false;

	state = {
		auth: false,
		// loading: true,
		modalToShow: false,
		isMenuCollapsed: false,
		isMenuFloated: false,
	};

	init = () => {
		this.setState({
			auth: true,
			stoppingMasquerading: false,
		});
		this.isMasquerading = MasqueradeService.isMasquerading();
		this.isResponderMasquerading = RespondService.isMasquerading();
		this.loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
		this.openStopMasqueradingConfirmModal = this.openStopMasqueradingConfirmModal.bind(this);
		this.closeStopMasqueradingConfirmModal = this.closeStopMasqueradingConfirmModal.bind(this);
		this.handleStopMasquerade = this.handleStopMasquerade.bind(this);
	};

	validateLocationPermission = (location, permissions) => {
		const permissionsCheck = {
			buildingHierarchy: permissions.buildings,
			buildingPermissions: permissions.permissions,
		};

		if (
			!checkPermission('user_view', permissionsCheck) &&
			location.includes('user-management')
		) {
			window.location.replace('/');
		}
	};

	checkAuth = () => {
		const { dispatch } = this.props;
		const {
			route: { user },
			history,
		} = this.props;
		if (user) {
			try {
				const loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
				const email =
					loggedUserData.person.email !== 'undefined'
						? loggedUserData.person.email
						: null;
				const personName =
					loggedUserData.person.name !== 'undefined' ? loggedUserData.person.name : null;
				const token = JSON.parse(localStorage.getItem('user')).jwt;
				const decoded = decodeToken(token);
				UsersService.fetchDistrictInfo(decoded.dist)
					.then(response => {
						/* eslint-disable no-undef */
						if (process.env.USEPENDO && decoded.id) {
							pendo.initialize({
								visitor: {
									id: decoded.id,
									email: email?.emailAddress,
									full_name: `${personName?.givenName} ${personName?.familyName}`,
								},
								account: {
									id: decoded.id,
									navId: response.navId,
								},
							});
						}
					})
					.catch((error) => {
						console.error('error', error);
						this.setState({ auth: true });
					});

				return UsersService.fetchPermissions()
					.then((response) => {
						const { isSysAdmin, buildings, permissions } = response;
						dispatch({
							type: 'SET_ROOT_PERMISSIONS',
							districtId: decoded.dist,
							isSysAdmin,
							buildings,
							permissions,
						});

						this.setState({ auth: true });

						this.validateLocationPermission(location.pathname, response);
					})
					.catch((error) => {
						console.error('error', error);
						if (error.statusCode == 404) {
							dispatch(resetState());
							return (location.href = '/login');
						}
						this.setState({ auth: false });
					});
			} catch (error) {
				return this.setState({ auth: true });
			}
		}
		this.setState({ auth: false });
		// if the user isn't logged in, and the page requested
		// isn't listed below, they'll be redirected to login
		if (
			history.location.pathname.includes('/login') ||
			history.location.pathname.includes('/sso') ||
			history.location.pathname.includes('/publicDrillLogs') ||
			history.location.pathname.includes('/set-password/') ||
			history.location.pathname.includes('/set-password-expired') ||
			history.location.pathname.includes('/reset-password-expired/') ||
			history.location.pathname.includes('/password-recovery') ||
			history.location.pathname.includes('/switch-district') ||
			history.location.pathname.includes('/maps/view') ||
			// TODO: Make this more specific to sp export.
			history.location.pathname.includes('/export')
		) {
			return null;
		}
		return history.replace('/login');
	};

	componentDidMount() {
		this.checkAuth();

		const {
			route: { user },
		} = this.props;
		if (user) {
			this.init();
		}

		this.checkFlashMsg();
	}

	componentDidUpdate(prevProps) {
		const {
			route: { user },
		} = this.props;
		if (prevProps.route.user !== user) {
			return this.checkAuth();
		}

		return null;
	}

	closeStopMasqueradingConfirmModal() {
		this.setModalToShow(null);
	}

	openStopMasqueradingConfirmModal() {
		this.setModalToShow('stopMasqueradingUserModal');
	}

	setModalToShow = (modalName) => {
		const { dispatch } = this.props;
		dispatch({
			type: MODAL_TO_SHOW,
			payload: modalName,
		});
	};

	handleStopMasquerade() {
		this.setState({ stoppingMasquerading: true });
		const { dispatch } = this.props;
		MasqueradeService.stopMasqueradingSession()
			.then((resp) => {
				if (resp.statusCode === 200) {
					const masqueradingUserToken = JSON.parse(
						localStorage.getItem('masqueradingUserToken'),
					).jwt;
					const masqueradingUserData = JSON.parse(
						localStorage.getItem('masqueradingUserData'),
					);
					// remove the stored token and data for masquerading
					localStorage.removeItem('masqueradingUserToken');
					localStorage.removeItem('masqueradingUserData');
					// overwrite the stored data with the real logged in user data
					dispatch({
						type: 'SET_USER',
						payload: masqueradingUserToken,
					});
					dispatch({
						type: 'SET_USER_DATA',
						payload: masqueradingUserData,
					});
					location.reload();
				} else {
					this.showToaster('Unable to perform this action', Intent.DANGER);
					this.setState({ stoppingMasquerading: false });
				}
			})
			.catch((err) => {
				this.showToaster(err.error.description, Intent.DANGER);
				this.setState({ stoppingMasquerading: false });
			});
	}

	handleStopResponderMasquerade = () => {
		this.setModalToShow('masqueradeLoadingModal');
		const { dispatch } = this.props;
		RespondService.stopResponderMasqueradingSession()
			.then((response) => {
				if (response.statusCode === 200) {
					// get token
					const { accessToken } = response.data;
					return UserService.fetchMyInfo(accessToken)
						.then(({ data }) => {
							const masqueradingUserToken = JSON.parse(
								localStorage.getItem('masqueradingUserToken'),
							).jwt;
							const masqueradingUserData = JSON.parse(
								localStorage.getItem('masqueradingUserData'),
							);
							// remove the stored token and data for masquerading
							localStorage.removeItem('masqueradingUserToken');
							localStorage.removeItem('masqueradingUserData');
							// overwrite current user info by masquerading user info
							dispatch({
								type: 'SET_USER',
								payload: masqueradingUserToken,
							});
							dispatch({
								type: 'SET_USER_DATA',
								payload: masqueradingUserData,
							});
							dispatch({
								type: 'SET_CURRENT_DISTRICT',
								payload: data.primaryBuilding,
							});
							this.setState({ isResponderMasquerading: false });
							location.replace('/');
						})
						.catch((err) => {
							this.setModalToShow(null);
							this.showToaster(err.error.description, Intent.DANGER);
						});
				}
				this.setModalToShow(null);
				this.showToaster('Stop Masquerading failed', Intent.DANGER);
			})
			.catch((err) => {
				this.setModalToShow(null);
				this.showToaster(err.error.description, Intent.DANGER);
			});
	};

	checkFlashMsg = () => {
		const msg = sessionStorage.getItem('flashSuccessMsg');

		if (msg) {
			this.showToaster(msg, Intent.SUCCESS);
			sessionStorage.removeItem('flashSuccessMsg');
		}
	};

	showToaster = (message, intent) => {
		const toastParams = {
			message,
			intent,
			timeout: 3000,
		};
		this.toaster.show(toastParams);
	};

	getSite = (responder = false) => {
		const currentDistrict = JSON.parse(localStorage.getItem('currentDistrict'));

		return responder
			? `${currentDistrict?.name}`
			: `${currentDistrict?.id} - ${currentDistrict?.name}`;
	};

	toggleMenuCollapse = () => {
		this.setState({ isMenuCollapsed: !this.state.isMenuCollapsed });
	};

	toggleMenuShow = () => {
		this.setState({ isMenuFloated: !this.state.isMenuFloated });
	};

	setMenuFloat = (isFloat) => {
		this.setState({ isMenuFloated: isFloat });
	};

	render() {
		const {
			route: { permissionsFetched, permissions, modalToShow },
			history,
		} = this.props;
		const { auth } = this.state;
		const pathUrl = window.location.pathname;

		// TODO: This method of sidebar switching needs to move to the "Layout" method.
		// @see: https://stackoverflow.com/questions/69718435/remove-sidebar-for-certain-route-react
		const isSwitchDistrictPage = pathUrl.includes('/switch-district');
		const isPrintPage = pathUrl.includes('/print');
		const isMapViewPage = pathUrl.includes('maps/view');
		const isMapEditPage = pathUrl.includes('maps/edit');
		const isSafetyPlansExport = pathUrl.includes('/export');

		const shouldHideSection =
			isSwitchDistrictPage ||
			isPrintPage ||
			isMapViewPage ||
			isMapEditPage ||
			isSafetyPlansExport;
		const hasMultiDistricts = parseInt(localStorage.getItem('hasMultiDistricts'), 10);

		const hasSiteCallout =
			auth &&
			process.env.STAGE !== 'production' &&
			hasMultiDistricts === 1 &&
			!shouldHideSection &&
			!this.isMasquerading &&
			!this.isResponderMasquerading;

		const hasMasqueradeCallout =
			auth && this.isMasquerading && !shouldHideSection && !this.isResponderMasquerading;
		const hasResponderMasqueradeCallout =
			auth && this.isResponderMasquerading && !shouldHideSection;

		// Add path classes.
		const pathClasses = pathUrl
			.slice(1)
			.split('/')
			.map((item, index) => `path-${index}-${item.toLowerCase().substring(0, 15)}`);
		const pageClasses = ['page-content', ...pathClasses];
		if (auth && !shouldHideSection) {
			pageClasses.push('side-menu');
		}
		if (this.state.isMenuCollapsed === true) {
			pageClasses.push('collapsed');
		}
		if (isMapViewPage || isMapEditPage) {
			pageClasses.push('map-view-container');
		}

		if (hasSiteCallout || hasMasqueradeCallout) {
			pageClasses.push('has-callout');
		}

		const pageClassName = pageClasses.join(' ');

		if (auth && !permissionsFetched) {
			return <LoadingSpinner />;
		}
		const districtToken = localStorage.getItem('districtToken');
		if (isSwitchDistrictPage && !districtToken) {
			return <Redirect to="/login" />;
		}

		// Remove the accessiBe logo
		const acsbButtonShadowRoot = document.querySelectorAll('access-widget-ui').item(2);
		const acsbButton = acsbButtonShadowRoot?.shadowRoot.querySelector('button');
		if (acsbButton) {
			acsbButton.hidden = true;
		}

		return (
			<>
				<ToasterNotification>
					<div className="page">
						{this.state.isMenuFloated && !shouldHideSection && (
							<div
								className="menu-backdrop"
								onClick={() => this.setMenuFloat(false)}
							/>
						)}
						<Helmet titleTemplate="%s | EMS" defaultTitle="EMS">
							<meta name="description" content="EMS" />
						</Helmet>
						{hasSiteCallout && (
							<Callout
								id="current-site-wrapper"
								icon="none"
								className="app-current-site"
							>
								<p className="text-center mb-0">
									Your current site is <strong>{this.getSite()}</strong>
								</p>
							</Callout>
						)}
						{hasMasqueradeCallout && (
							<Callout
								intent="danger"
								icon="none"
								className="app-current-site is-masquerading"
							>
								<p className="text-center mb-0">
									{hasMultiDistricts === 1 && process.env.STAGE !== 'production' && (
										<>
											Your current site is <strong>{this.getSite()}</strong>{' '}
											and{' '}
										</>
									)}
									you are masquerading as{' '}
									<strong>
										{`${this.loggedUserData.person.name.givenName} ${
											this.loggedUserData.person.name.familyName
										}`}
									</strong>
									<div className="stop-masquerad-button-container">
										<AnchorButton
											rightIcon="share"
											target="_blank"
											text="Exit Masquerade"
											small
											onClick={this.openStopMasqueradingConfirmModal}
										/>
									</div>
								</p>
							</Callout>
						)}
						{hasResponderMasqueradeCallout && (
							<Callout
								intent="Success"
								icon="none"
								className="first-responder-callout"
							>
								<p className="text-center mb-0">
									{process.env.STAGE !== 'production' && (
										<>
											You’re currently viewing {this.getSite(true)}
											{'. '}
										</>
									)}
									Go back to the portal to change sites.
									<div className="stop-responder-masquerade-button-container">
										<Button
											type="primaryDefault"
											intent="default"
											text="First Responder Portal"
											rightIcon="share"
											onClick={() => this.handleStopResponderMasquerade()}
										/>
									</div>
								</p>
							</Callout>
						)}
						<MenuContext.Provider value={this.toggleMenuShow}>
							{!shouldHideSection && (
								<Sidebar
									auth={auth}
									permissionsFetched={permissionsFetched}
									permissions={permissions}
									history={history}
									isMenuCollapsed={this.state.isMenuCollapsed}
									isMenuFloated={this.state.isMenuFloated}
									setMenuFloat={this.setMenuFloat}
									toggleMenuCollapse={this.toggleMenuCollapse}
									className={
										pageClasses.includes('has-callout')
											? 'page-has-callout'
											: ''
									}
									districtLabelExists={
										!!(hasMultiDistricts == 1 || this.state.isMasquerading)
									}
									setModalToShow={this.setModalToShow}
								/>
							)}
							<div
								className={pageClassName}
								auth={!shouldHideSection.toString() || auth.toString()}
							>
								<Toaster
									ref={(ref) => {
										this.toaster = ref;
									}}
									intent="#21130d"
									position={Position.TOP_LEFT}
								/>
								<Switch>
									{/* <Route exact path="/">
										<Redirect to="/safety-plans" />
									</Route> */}
									{routes.map((item, index) =>
										!auth && item.protected ? (
											<Redirect to="/login" />
										) : (
											<Route
												key={index}
												path={item.link}
												component={item.component}
												exact
											/>
										),
									)}
								</Switch>
							</div>
						</MenuContext.Provider>
						<GlobalStyle />
					</div>
					{modalToShow === 'stopMasqueradingUserModal' && (
						<ConfirmationModal
							title="Stop Masquerading?"
							text={[<p>Are you sure you want to stop masquerading as this user?</p>]}
							confirmBtnTxt="Yes"
							closeModal={this.closeStopMasqueradingConfirmModal}
							submitModel={this.handleStopMasquerade}
							loading={this.state.stoppingMasquerading}
						/>
					)}
					{modalToShow === 'activateAlarmModal' && (
						<ActivateAlarmModal history={this.props.history} mode="ALARM" />
					)}
					{modalToShow === 'masqueradeLoadingModal' && (
						<LoadingModal
							isOpen
							loading
							text="Taking you back to First Responder's Portal..."
							onClose={() => {}}
						/>
					)}
				</ToasterNotification>
			</>
		);
	}
}

App.propTypes = {
	route: PropTypes.object,
	history: PropTypes.object,
	dispatch: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	route: selectRoute(),
});

function mapDispatchToProps(dispatch) {
	return {
		dispatch,
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export { ToasterContext };
export default compose(withConnect)(App);
