import React, { useState, useEffect, useContext } from 'react';
import {
	Breadcrumb,
	Button,
	ActionsButton,
	MediaPlayer,
	CardContainer,
	Badge,
	LoadingSpinner,
	ToasterContext,
	Intent,
	ConfirmationModal,
	FeaturedIcon,
} from 'componentsV2';

import styled from 'styled-components';
import Swal from 'sweetalert2';
import { ScenariosService } from 'services/ScenariosService';
import { ScenariosCategoriesService } from 'services/ScenariosCategoriesService';
import { BuildingsService } from 'services/BuildingsService';
import { DrillLogService } from 'services/DrillLogService';
import { CATEGORIES_BADGE_MAP, ACCESS_SETTINGS_MODULES } from 'utilities/constants';
import { formatDate } from 'utilities/dates';
import { MarkAsUsedModal } from './MarkAsUsedModal';
import { UsageHistory } from './UsageHistory';
import AddEditCustomScenarioModal from 'components/Modals/AddEditCustomScenarioModal';
import { filterSitesPerPermissionCode, checkPermissionOnDistrict } from 'utilities/permissions';
import { useSelector } from 'react-redux';

const Page = styled.div`
	.header {
		margin: 32px 0;
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
	.header-content {
		display: flex;
		justify-content: space-between;
		align-items: start;
		margin-top: 16px;
		margin-bottom: 16px;
	}
	.actions {
		display: flex;
		gap: 12px;
	}
	.breadcrumb-container {
		margin-top: 24px;
	}
	.actions-btn-container {
		padding: 14px 10px;
		border: 1px solid rgba(217, 220, 221, 1);
		height: 44px;
		border-radius: 12px;
		display: flex;
		align-items: center;
	}
	.actions-btn-container:hover {
		background: #f4f5f5;
	}
	.content {
		display: flex;
		gap: 32px;
	}
	.left {
		flex: 2;
		display: flex;
		flex-direction: column;
		gap: 32px;
	}
	.right {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 32px;
	}
	.sub-title {
		font-size: 16px;
		font-weight: 700;
		color: rgba(41, 53, 61, 1);
		line-height: 24px;
	}
	.description {
		font-family: Nunito Sans;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		color: rgba(105, 114, 119, 1);
	}
	.discussion-points {
		font-family: Nunito Sans;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		color: rgba(105, 114, 119, 1);
		white-space: pre-wrap;
	}
	.details-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		gap: 16px;
	}
	.detail-name {
		font-weight: 600;
		font-size: 14px;
		color: rgba(105, 114, 119, 1);
	}
	.detail-value {
		font-weight: 600;
		font-size: 16px;
		color: rgba(41, 53, 61, 1);
	}
	.no-usage {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: rgba(105, 114, 119, 1);
	}
	.title > p {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: rgba(41, 53, 61, 1);
	}
`;

const breadCrumbs = [
	{ label: 'Drills', url: '/drillsV2' },
	{ label: 'Scenarios', url: '/drillsV2/scenarios' },
	{ label: 'Scenario details' },
];

export const ScenarioDetailsV2 = ({ match, history }) => {
	const [scenario, setScenario] = useState({});
	const [mediaUrl, setMediaUrl] = useState('');
	const [usageHistory, setUsageHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [showModal, setShowModal] = useState('');
	const [loadingLog, setLoadingLog] = useState(false);
	const [categories, setCategories] = useState([{}]);
	const [buildings, setBuildings] = useState([]);

	const permissions = useSelector((state) => state.route.permissions);
	const hasPermissionOnDistrict = checkPermissionOnDistrict('drill_scenario_edit');

	const fetchBuildings = async () => {
		await BuildingsService.getAll(false, false, ACCESS_SETTINGS_MODULES.DRILL_LOGS).then(
			(serviceBuildings) => {
				let sites = filterSitesPerPermissionCode(
					'drill_scenario_edit',
					permissions,
					serviceBuildings,
				);
				if (hasPermissionOnDistrict) {
					sites.unshift({ label: 'Visible to all', value: null });
				}
				setBuildings(sites);
			},
		);
	};

	const fetchCategories = async () => {
		const categoriesInfo = await ScenariosCategoriesService.getAll();
		const categoryList = categoriesInfo.data.map((item) => ({
			label: item.title,
			value: item.id,
		}));
		setCategories(categoryList);
	};

	const fetchScenarioData = async () => {
		setIsLoading(true);
		const scenarioData = await ScenariosService.getScenario(match.params.id);
		const usageData = await ScenariosService.getUsageHistory(match.params.id);
		setScenario(scenarioData);
		setUsageHistory(usageData);
		if (scenarioData.audioFile) {
			setMediaUrl(scenarioData.audioFile);
		} else if (scenarioData.videoFile) {
			setMediaUrl(scenarioData.videoFile);
		}

		// Get data for edit modal
		await fetchCategories();
		await fetchBuildings();

		setIsLoading(false);
	};

	useEffect(() => {
		fetchScenarioData().catch(console.error);
	}, []);

	const closeModal = () => setShowModal('');

	const toaster = useContext(ToasterContext);

	const markAsUsed = async (data) => {
		if (data.buildingId && data.dateUsed && data.scenarioType) {
			try {
				setLoadingLog(true);
				const res = await ScenariosService.markAsUsed(data);
				setLoadingLog(false);
				closeModal();
				toaster(
					`You have successfully marked "${scenario.title}" as used.`,
					Intent.SUCCESS,
				);
				// reload history
				const usageData = await ScenariosService.getUsageHistory(match.params.id);
				setUsageHistory(usageData);
			} catch (error) {
				closeModal();
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Error logging scenario usage',
				});
			}
		}
	};

	const handlEnableAction = async () => {
		const status = scenario.enabled ? 0 : 1;

		try {
			setIsLoading(true);
			await ScenariosService.enableAndDisableScenario(scenario.id, status);
			setIsLoading(false);
			closeModal();
			if (status == 0) {
				toaster(
					`${'You have successfully disabled ' + '"'}${scenario.title}"!`,
					Intent.NONE,
					<FeaturedIcon icon="disable" type="Gray" size="md" shape="circleIcon" />,
					2000,
				);
			} else {
				toaster(
					`You have successfully enabled "'${scenario.title}"!`,
					Intent.SUCCESS,
					'',
					2000,
				);
			}

			fetchScenarioData();
		} catch (error) {
			setIsLoading(false);
			console.error(error);
			toaster(`Failed to  ${status == 0 ? 'disable!' : 'enable!'}`, 'danger');
		}
	};

	const deleteScenario = async () => {
		setIsLoading(true);
		await DrillLogService.removeDrillLogScenario(scenario.id)
			.then((res) => {
				if (res.statusCode == 200) {
					setShowModal('');
					// Navigate back to list view
					history.push('/drillsV2/scenarios');
					setIsLoading(false);
					toaster(
						'You have successfully deleted "' + scenario.title + '"',
						Intent.NONE,
						<FeaturedIcon icon={'trash'} type={'Gray'} />,
					);
				} else {
					setShowModal('');
					setIsLoading(false);
					toaster(
						'Scenario "' + scenario.title + '" was not deleted',
						Intent.DANGER,
						<FeaturedIcon icon={'error'} type={'Error'} />,
					);
				}
			})
			.catch((err) => {
				setShowModal('');
				setIsLoading(false);
				toaster(
					err?.error?.description,
					Intent.DANGER,
					<FeaturedIcon icon={'error'} type={'Error'} />,
				);
			});
	};

	if (isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<>
			<Page>
				<div className="breadcrumb-container">
					<Breadcrumb items={breadCrumbs} />
				</div>
				<div className="header-content">
					<div className="title">
						<h2>{scenario.title}</h2>
						<p>{scenario.staticId ? 'Preset Scenario' : 'Custom Scenario'}</p>
					</div>
					<div className="actions">
						<Button
							type="tertiary"
							size="lg"
							text="Edit"
							icon="edit"
							onClick={() => setShowModal('edit')}
						/>
						<div>
							<ActionsButton
								elementSelector={<Button type="tertiary" size="lg" icon="more" />}
							>
								<Button
									type="ghostDefault"
									size="lg"
									text="Mark as used"
									icon="tick"
									onClick={() => setShowModal('markAsUsed')}
									intent="default"
									wrapperClass="displayBlock"
									className="actionButtonElement"
								/>
								<Button
									type="ghostDefault"
									size="lg"
									text={scenario.enabled ? 'Disable' : 'Enable'}
									icon={scenario.enabled ? 'disable' : 'tick'}
									onClick={() => setShowModal('disable')}
									intent="default"
									wrapperClass="displayBlock"
									className="actionButtonElement"
								/>
								<Button
									type="ghostDefault"
									size="lg"
									text="Delete"
									icon="trash"
									onClick={() => setShowModal('delete')}
									intent="default"
									wrapperClass="displayBlock"
									className="actionButtonElement"
								/>
							</ActionsButton>
						</div>
					</div>
				</div>
				<div className="content">
					<div className="left">
						{mediaUrl && <MediaPlayer mediaUrl={mediaUrl} />}
						{scenario.content && (
							<div>
								<h3 className="sub-title">Scenario description</h3>
								<p className="description">{scenario.content}</p>
							</div>
						)}
						{scenario?.notes && (
							<div>
								<h3 className="sub-title">Discussion Points</h3>
								<div className="discussion-points">{scenario?.notes}</div>
							</div>
						)}
					</div>
					<div className="right">
						<CardContainer title="Details">
							<div className="details-grid">
								<div className="details-grid-item">
									<p className="detail-name">Date created</p>
									<p className="detail-value">
										{scenario.dateEntered
											? formatDate(scenario.dateEntered)
											: ''}
									</p>
								</div>
								<div className="details-grid-item">
									<p className="detail-name">Created by</p>
									<p className="detail-value">{scenario.enteredBy}</p>
								</div>
								<div className="details-grid-item">
									<p className="detail-name">Last modified</p>
									<p className="detail-value">
										{scenario.lastModified
											? formatDate(scenario.lastModified)
											: ''}
									</p>
								</div>
								<div className="details-grid-item">
									<p className="detail-name">Category</p>
									{scenario.category ? (
										<Badge
											text={scenario.category.title}
											type={CATEGORIES_BADGE_MAP[scenario.category.title]}
											size="lg"
										/>
									) : (
										''
									)}
								</div>
							</div>
						</CardContainer>
						<CardContainer title="Usage history">
							{usageHistory.length > 0 ? (
								<UsageHistory usageHistory={usageHistory} />
							) : (
								<p className="no-usage">There is no usage history yet</p>
							)}
						</CardContainer>
					</div>
				</div>
			</Page>
			{showModal === 'edit' && (
				<AddEditCustomScenarioModal
					onCloseModal={() => setShowModal('')}
					editing={true}
					scenarioId={scenario.id}
					updateDataCallBack={fetchScenarioData}
					categories={categories}
					buildings={buildings}
				/>
			)}
			{showModal === 'markAsUsed' && (
				<MarkAsUsedModal
					closeModal={closeModal}
					markAsUsed={markAsUsed}
					scenario={scenario}
					loadingLog={loadingLog}
				/>
			)}
			<ConfirmationModal
				isOpen={showModal === 'disable'}
				title={`Are you sure you want to ${scenario.enabled ? 'disable ' : 'enable '}"${
					scenario.title
				}"?`}
				subtitle={`You can ${scenario.enabled ? `enable` : `disable`} it at a later time.`}
				showCloseButton={false}
				cancelBtn={{ title: 'No, Go back', onClick: () => setShowModal('') }}
				confirmBtn={{
					title: `Yes, ${scenario.enabled ? 'Disable ' : 'Enable '}It`,
					onClick: handlEnableAction,
				}}
				loading={isLoading}
				btnsSize="lg"
			/>
			<ConfirmationModal
				isOpen={showModal === 'delete'}
				title={'Are you sure you want to delete ' + `"` + scenario.title + `"?`}
				subtitle={'Once deleted, this cannot be recovered.'}
				showCloseButton={false}
				icon={{ icon: 'trash', iconColor: 'error' }}
				onClose={() => setShowModal('')}
				cancelBtn={{ title: 'No, go back', onClick: () => setShowModal('') }}
				confirmBtn={{
					title: 'Yes, delete it',
					onClick: deleteScenario,
					type: 'primaryDanger',
				}}
				loading={isLoading}
			/>
		</>
	);
};
