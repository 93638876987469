import React from 'react';
import { AlertModal, Button } from 'componentsV2';

export const FirstResponderUnsavedChangesModal = ({
	onUnsavedModalConfrimActionHandler,
	setShowUnsavedChangesModal,
}) => {
	return (
		<>
			<AlertModal
				isOpen={true}
				loading={false}
				size="medium"
				title="Are you sure?"
				subtitle="These changes will not be saved. Do you want to continue without saving?"
				showCloseButton={false}
				alertConfirmButton={
					<Button
						text={'Continue'}
						type="primaryDefault"
						size="md"
						onClick={onUnsavedModalConfrimActionHandler}
					/>
				}
				alertCancelButton={
					<Button
						text="Cancel"
						type="tertiary"
						size="md"
						onClick={() => setShowUnsavedChangesModal(false)}
					/>
				}
				icon="warning-sign"
				iconColor="error"
			/>
		</>
	);
};
