import React, { useState, useEffect, useRef } from 'react';
import { DatePicker, TimePrecision } from '@blueprintjs/datetime';
import { Icon } from '@blueprintjs/core';
import { Button } from 'componentsV2';
import { formatDate } from './datesHelper';
import './DateTimePicker.scss';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

export const DateTimePicker = ({
	initialValue = null,
	placeholder = 'Select date and time...',
	label = '',
	labelRequired = false,
	showTime = true,
	shortcuts = false,
	luxonDateFormat = DateTime.DATETIME_SHORT,
	onSubmit = () => {},
	onChange = () => {},
	errorMessage = '',
	minDate,
	maxDate,
	initialMonth,
	isDisabled = false,
}) => {
	const [showCalendar, setShowCalendar] = useState(false);
	const [selectedDate, setSelectedDate] = useState(null);
	const containerRef = useRef(null);

	const maxDateValue = new Date();
	maxDateValue.setFullYear(maxDateValue.getFullYear() + 2);

	const maxDateForDatePicker = maxDate ? maxDate : maxDateValue;

	const submitDate = () => {
		onSubmit(selectedDate);
		setShowCalendar(false);
	};

	// NOTE - If there is an initialValue prop passed to this component, the date is set in the intial render
	// We do not need to keep executing the useEffect if there is an initial value and if the value is changed by
	// picking up a new date from the dattime picker we have onChangeHandler() that sets the selected date.
	useEffect(() => {
		onChangeHandler(initialValue);
	}, []);

	const onChangeHandler = date => {
		setSelectedDate(date);
		onChange(date);
	};

	// Effect to hide calendar if clicked outside
	useEffect(() => {
		function handleClickOutside(event) {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				setShowCalendar(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [containerRef, selectedDate]);

	const datePickerValue = selectedDate ? selectedDate : initialMonth ? initialMonth : minDate;
	const initialMonthValue = initialMonth ? initialMonth : minDate;
	const timePrecisionValue = showTime ? TimePrecision.MINUTE : undefined;

	return (
		<>
			{/* Label */}
			{label && (
				<div className="datetime-picker-label-container">
					<span className="datetime-picker-label">{label}</span>
					{labelRequired ? (
						<span className="datetime-picker-label-required">*</span>
					) : (
						<span className="datetime-picker-label-optional">(Optional)</span>
					)}
				</div>
			)}

			<div className="datetime-picker-container" ref={containerRef}>
				{/* Button */}
				<button
					className={`datetime-picker-btn ${errorMessage ? 'error' : ''}`}
					onClick={() => setShowCalendar(!showCalendar)}
				>
					<Icon icon="calendar" className="datetime-picker-btn-icon" />
					<span
						className={`datetime-picker-btn-title ${
							!selectedDate ? 'place-holder' : ''
						}`}
					>
						{selectedDate ? formatDate(selectedDate, luxonDateFormat) : placeholder}
					</span>
				</button>
				{/* Error state */}
				{errorMessage && <div className="datetime-picker-error">{errorMessage}</div>}
				{/* Calendar input */}
				<div className={`datetime-picker-calendar ${showCalendar ? 'expanded' : ''}`}>
					<DatePicker
						minDate={minDate}
						maxDate={maxDateForDatePicker}
						initialMonth={initialMonthValue}
						onChange={onChangeHandler}
						timePrecision={timePrecisionValue}
						timePickerProps={
							showTime
								? {
										useAmPm: true,
										showArrowButtons: true,
										value: datePickerValue,
								  }
								: undefined
						}
						shortcuts={shortcuts}
						value={datePickerValue}
					/>
					<div className="datetime-picker-calendar-btn">
						<div className="datetime-picker-calendar-btn-left-side">
							<Button
								type="ghostDefault"
								text="Clear"
								onClick={() => onChangeHandler(null)}
							/>
						</div>
						<div className="datetime-picker-calendar-btn-right-side">
							<Button
								type="tertiary"
								text="Cancel"
								onClick={() => setShowCalendar(false)}
							/>
							<Button
								type="primaryDefault"
								text="Save"
								onClick={submitDate}
								disabled={isDisabled}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

DateTimePicker.propTypes = {
	initialValue: PropTypes.any,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	labelRequired: PropTypes.bool,
	showTime: PropTypes.bool,
	onSubmit: PropTypes.func,
	onChange: PropTypes.func,
	shortcuts: PropTypes.bool,
};
