// import { store } from '../configureStore';
import { useSelector } from 'react-redux';
import { decodeToken } from 'utilities';
import { isOn, names } from './flags';

/*
    The method checks if the user has permissions for accessing a given entity/feature

	Usage:
		checkPermission('user_view') // return true if user has the user_view permission for at least one building
		checkPermission('user_view', 3001) // return true if user has user_view permission for building 3001 or one of its parents
		const buildings = getBuildingsByPermission('user_view') // return all buildings that have (or its parents have) the user_view permission
 */

export const checkPermission = (permission, permissions, buildingId) => {
	// EIT-4176: Lockout non-paying sites from anything other than user management and safety plans.
	if (isOn(names.IS_SAFETY_PLANS_ONLY)) {
		if (!permission.match('(safety_plans*)|(user*)')) {
			return false;
		}
	}

	if (buildingId) {
		return isPermissionTiedToBuilding(permission, permissions, buildingId);
	}

	const buildingPermissionsArray = Object.values(permissions.buildingPermissions);

	for (let i = 0; i < buildingPermissionsArray.length; i += 1) {
		const permissionsMap = buildingPermissionsArray[i];

		if (permissionsMap[permission]) {
			return true;
		}
	}

	return false;
};

export const checkPermissionByCodeAndSites = (
	code,
	userPermissions,
	objectSiteIds,
	method = 'every',
) => {
	if (userPermissions.isSysAdmin) {
		return true;
	}

	const { buildingPermissions, buildingHierarchy } = userPermissions;

	// Return true if permission code matches, recurse if parent exists
	const hasAccess = (siteId, code) => {
		if (buildingPermissions[siteId] && buildingPermissions[siteId][code]) {
			return true;
		}
		const parentSiteId = buildingHierarchy[siteId] && buildingHierarchy[siteId].parent;
		if (parentSiteId) {
			return hasAccess(parentSiteId, code);
		}
		return false;
	};

	if (method === 'every') {
		return objectSiteIds.every(siteId => hasAccess(siteId, code));
	}
	if (method === 'some') {
		return objectSiteIds.some(siteId => hasAccess(siteId, code));
	}
	throw new Error(`Invalid method: ${method}`);
};

export const filterSitesPerPermissionCode = (code, userPermissions, sites) => {
	if (userPermissions.isSysAdmin) {
		return sites;
	}

	const { buildingPermissions, buildingHierarchy } = userPermissions;

	// Return true if permission code matches, recurse if parent exists
	const hasAccess = (siteId, code) => {
		if (buildingPermissions[siteId] && buildingPermissions[siteId][code]) {
			return true;
		}
		const parentSiteId = buildingHierarchy[siteId] && buildingHierarchy[siteId].parent;
		if (parentSiteId) {
			return hasAccess(parentSiteId, code);
		}
		return false;
	};

	const filteredSites = sites.filter(site => hasAccess(site.id, code));

	return filteredSites;
};

export const isPermissionTiedToBuilding = (permission, permissions, buildingId) => {
	if (permissions.isSysAdmin) {
		return true;
	}

	const { buildingPermissions, buildingHierarchy } = permissions;

	// Return true if permission code matches, recurse if parent exists
	const hasAccess = (siteId, code) => {
		if (buildingPermissions[siteId] && buildingPermissions[siteId][code]) {
			return true;
		}
		const parentSiteId = buildingHierarchy[siteId] && buildingHierarchy[siteId].parent;
		if (parentSiteId) {
			return hasAccess(parentSiteId, code);
		}
		return false;
	};

	const hasPermission = hasAccess(buildingId, permission);

	return hasPermission;
};

export const getBuildingsByPermission = (permission, permissions) =>
	Object.values(permissions.buildingHierarchy).filter(building =>
		isPermissionTiedToBuilding(permission, permissions, building.id),
	);

export const checkPermissionOnDistrict = permissionCode => {
	const permissions = useSelector(state => state.route.permissions);

	const token = JSON.parse(localStorage.getItem('user')).jwt;
	const dist = token ? decodeToken(token) : '';
	const districtId = dist ? dist.dist : null;

	if (districtId) {
		return isPermissionTiedToBuilding(permissionCode, permissions, districtId);
	}

	return false;
};
