import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, LoadingSpinner, ToasterContext, Intent } from 'componentsV2';
import { DistrictsService, UsersService } from 'services';
import { logoNavigateSwitch, logoNavigateDark } from 'assets/icons';
import { useDispatch } from 'react-redux';
import { createBrowserHistory } from 'history';
import { UserService } from 'services/UserService';
import './css/DistrictSwitcher.scss';
import { SITE_FIRST_RESPONDER } from '../../utilities/constants';

export const DistrictSwitcher = ({ props }) => {
	const toaster = useContext(ToasterContext);
	const [districtsList, setDistrictsList] = useState([]);
	const [selectedDistrict, setSelectedDistrict] = useState({});
	const [loading, setLoading] = useState(true);
	const districtToken = localStorage.getItem('districtToken');
	const dispatch = useDispatch();
	const history = createBrowserHistory();

	useEffect(() => {
		DistrictsService.getAll(districtToken)
			.then((data) => {
				setDistrictsList(
					data.map((item) => {
						return {
							value: item.id,
							name: item.name,
							label: `${item.id} - ${item.name}`,
						};
					}),
				);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				toaster(err.error.description, Intent.DANGER);
			});
	}, []);

	const submitDistrict = () => {
		setLoading(true);
		DistrictsService.selectDistrict(selectedDistrict.value, districtToken)
			.then((resp) => {
				if (
					(resp.statusCode && resp.statusCode === 200) ||
					(resp.statusCode && resp.statusCode === 300)
				) {
					if (resp.data.accessToken) {
						const { accessToken } = resp.data;
						UserService.fetchMyInfo(accessToken)
							.then((resp) => {
								if (resp.statusCode === 200) {
									dispatch({
										type: 'SET_USER',
										payload: accessToken,
									});
									dispatch({
										type: 'SET_CURRENT_DISTRICT',
										payload:
											// If a first responder goes through the district select screen, we need to make sure SET_CURRENT_DISTRICT takes in their `primaryBuilding` value
											// The FR portal expects CURRENT_DISTRICT_KEY to be the `primaryBuilding` value
											Number(selectedDistrict.value) === SITE_FIRST_RESPONDER
												? resp.data.primaryBuilding
												: {
														id: selectedDistrict.value,
														label: selectedDistrict.label,
														name: selectedDistrict.name,
												  },
									});
									dispatch({
										type: 'SET_USER_DATA',
										payload: resp.data,
									});

									// Fetch district info from the endpoint and store it for flags.
									return UsersService.fetchDistrictInfo(selectedDistrict.value);
								}
							})
							.then((response) => {
								// Store district info.
								localStorage.setItem('distInfo', JSON.stringify(response));

								// Fully refresh after district switch.
								history.push('/');
								window.location.reload();
							});
					}
				} else {
					setLoading(false);
					toaster(resp.error.description, Intent.DANGER);
					setSelectedDistrict(null);
				}
			})
			.catch((err) => {
				setLoading(false);
				toaster(err.error.description, Intent.DANGER);
				setSelectedDistrict(null);
			});
	};

	return (
		<>
			{loading && <LoadingSpinner />}
			{!loading && (
				<div className="district-switcher-container">
					<div className="district-switcher-wrapper">
						<img src={logoNavigateSwitch} alt="logo navigate" className="w-100" />
						<div className="district-switcher-box">
							<div className="switcher-title">Select your district</div>
							<div className="switcher-controls">
								<div className="w-100">
									{districtsList.length > 0 && (
										<Dropdown
											isRequired
											width="100%"
											placeholder="Select a site"
											value={selectedDistrict}
											onChange={setSelectedDistrict}
											options={districtsList}
											hasSearch
										/>
									)}
								</div>
								<Button
									large
									wrapperClass="w-100"
									text="Enter"
									type="primaryDefault"
									intent="default"
									disabled={selectedDistrict === null}
									onClick={submitDistrict}
								/>
							</div>
						</div>
					</div>
					<div>
						<img src={logoNavigateDark} alt="logo navigate" className="w-100" />
					</div>
				</div>
			)}
		</>
	);
};

DistrictSwitcher.propTypes = {
	error: PropTypes.string,
};
