export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';
export const SET_USER_DATA = 'SET_USER_DATA';
export const UPDATE_SUBSECTION = 'UPDATE_SUBSECTION';
export const REORDER_SECTIONS = 'REORDER_SECTIONS';
export const INDICATOR_RED = 'INDICATOR_RED';
export const LOAD_TEMPLATE = 'LOAD_TEMPLATE';
export const UPDATE_SECTION_TITLE = 'UPDATE_SECTION_TITLE';
export const UPDATE_SUBSECTION_TITLE = 'UPDATE_SUBSECTION_TITLE';
export const UPDATE_TEMPLATE_NAME = 'UPDATE_TEMPLATE_NAME';
export const ADD_SUBSECTION = 'ADD_SUBSECTION';
export const ADD_SECTION = 'ADD_SECTION';
export const AUTO_SAVE = 'AUTO_SAVE';
export const AUTO_SAVE_INIT = 'AUTO_SAVE_INIT';
export const UPDATE_SECTION_CONTENT = 'UPDATE_SECTION_CONTENT';
export const REMOVE_SECTION = 'REMOVE_SECTION';
export const REMOVE_SECTION_NEW = 'REMOVE_SECTION_NEW';
export const REMOVE_SUBSECTION = 'REMOVE_SUBSECTION';
export const REMOVE_SUBSECTION_NEW = 'REMOVE_SUBSECTION_NEW';
export const ADD_DOCUMENT_UPLOAD = 'ADD_DOCUMENT_UPLOAD';
export const FLIP_REUPLOAD = 'FLIP_REUPLOAD';
export const ADD_FILE = 'ADD_FILE';
export const ADD_VALUE_FILE = 'ADD_VALUE_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_VALUE_FILE = 'DELETE_VALUE_FILE';
export const CLEAR_TEMPLATE_DATA = 'CLEAR_TEMPLATE_DATA';
export const NEXT_SECTION = 'NEXT_SECTION';
export const ADD_BONUS_FILES = 'ADD_BONUS_FILES';
export const MARK_SECTION_COMPLETE = 'MARK_SECTION_COMPLETE';
export const MARK_SUBSECTION_COMPLETE = 'MARK_SUBSECTION_COMPLETE';
export const SET_SCROLL_MODE = 'SET_SCROLL_MODE';
export const UNSET_SCROLL_MODE = 'UNSET_SCROLL_MODE';
export const REMOVE_BONUS_FILE = 'REMOVE_BONUS_FILE';
export const SET_DISTRICT_ID = 'SET_DISTRICT_ID';
export const SET_ROOT_PERMISSIONS = 'SET_ROOT_PERMISSIONS';
export const AUTO_SAVE_TIME = 'AUTO_SAVE_TIME';
export const DISTRICT_PLANS_SITE = 'DISTRICT_PLANS_SITE';
export const SET_DISTRICT_TOKEN = 'SET_DISTRICT_TOKEN';
export const SET_MASQUERADING_USER_TOKEN = 'SET_MASQUERADING_USER_TOKEN';
export const SET_MASQUERADING_USER_DATA = 'SET_MASQUERADING_USER_DATA';
export const SET_CURRENT_DISTRICT = 'SET_CURRENT_DISTRICT';
export const SET_CALL_LIST_SITES = 'SET_CALL_LIST_SITES';
export const SET_SELECTED_SITES = 'SET_SELECTED_SITES';
export const SET_CALL_LIST_VIEW_TYPE = 'SET_CALL_LIST_VIEW_TYPE';
export const PHONE_NUMBER_PLACEHOLDER = '(111) 222-3333';
export const PHONE_NUMBER_MASK = [
	'(',
	/\d/,
	/\d/,
	/\d/,
	')',
	' ',
	/\d/,
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];
export const USER_ACCESS_TYPES = {
	FIRST_RESPONDER: 1,
	CUSTOMER: 2,
	NAVIGATE_ADMIN: 3,
	LIMITED_ACCESS: 4,
	OUTSIDE_USER: 5,
};
export const PHONE_TYPES = {
	WORK: 'work',
	MOBILE: 'mobile',
	HOME: 'home',
};
export const SORTING_DIR = {
	DESC: 'desc',
	ASC: 'asc',
};

export const FLIPCHART_CONTENT_TYPE = {
	CUSTOM: 'custom',
	CALLLIST: 'call_list',
	SAFETYPLAN: 'safety_plan',
};

export const CATEGORIES_BADGE_MAP = {
	Numeric: 'Gray',
	Fire: 'Warning',
	Medical: 'BlueDark',
	'Intruder/Hostile': 'Warning',
	'Student/Hostile': 'Orange',
	other: 'Gray',
	Maintenance: 'Fuchsia',
	'Bomb Threat': 'Error',
	'Bus / Transport': 'Success',
	Wildlife: 'Success',
	Weather: 'Primary',
};

export const LOG_TEMPLATE_RESET = 'TEMPLATE_RESET';
export const LOG_TEMPLATE_CREATED = 'TEMPLATE_CREATED';
export const LOG_TEMPLATE_UPDATED = 'TEMPLATE_UPDATED';
export const LOG_TEMPLATE_DELETED = 'TEMPLATE_DELETED';
export const LOG_TEMPLATE_ARCHIVED = 'TEMPLATE_ARCHIVED';
export const LOG_TEMPLATE_UNARCHIVED = 'TEMPLATE_UNARCHIVED';
export const LOG_TEMPLATE_CLONED = 'TEMPLATE_CLONED';
export const LOG_TEMPLATE_PUBLISHED = 'TEMPLATE_PUBLISHED';
export const LOG_SECTION_ADDED = 'SECTION_ADDED';
export const LOG_SECTION_TITLE_UPDATED = 'SECTION_TITLE_UPDATED';
export const LOG_SECTION_CONTENT_UPDATED = 'SECTION_CONTENT_UPDATED';
export const LOG_SECTION_DELETED = 'SECTION_DELETED';
export const LOG_SUBSECTION_ADDED = 'SUBSECTION_ADDED';
export const LOG_SUBSECTION_TITLE_UPDATED = 'SUBSECTION_TITLE_UPDATED';
export const LOG_SUBSECTION_CONTENT_UPDATED = 'SUBSECTION_CONTENT_UPDATED';
export const LOG_SUBSECTION_DELETED = 'SUBSECTION_DELETED';

export const INTERNAL_SITE_IDS = [
	5235, // Navigate School District.
	6880, // Core Assess Templates.
	1720, // Navigate City Schools Demo site.
	11320, // Clever sandbox site.
];
export const SITE_TEMPLATE_BASE = 6880;
export const SITE_FIRST_RESPONDER = 5304;
export const EMS_HOST_ENVS = {
	'ems.dev.navigatevm.com': 'dev',
	'ems.qa.navigatevm.com': 'qa',
	'ems.uat.navigatevm.com': 'uat',
	'ems.navigatevm.com': 'prod',
	'ems.navigate360.com': 'prod',
};
export const DELAWARE_SITE_IDS = [
	8696,
	8697,
	8682,
	8683,
	8698,
	8684,
	8692,
	8693,
	8685,
	8686,
	8694,
	8699,
	8687,
	8700,
	8701,
	8702,
	15746,
	8688,
	8689,
	8680,
	8695,
	8681,
	8690,
	8691,
	8383,
];

export const IMAGE_MIME_TYPES = ['image/jpeg', 'image/gif', 'image/bmp', 'image/tiff', 'image/png'];
export const VIDEO_MIME_TYPES = [
	'video/mp4',
	'video/mpeg',
	'video/ogg',
	'video/webm',
	'video/x-msvideo',
];
export const AUDIO_MIME_TYPES = ['audio/mp3', 'audio/mpeg', 'audio/mpeg3', 'audio/x-mpeg-3'];
export const PDF_MIME_TYPE = 'application/pdf';
export const PPT_MIME_TYPE = 'application/vnd.ms-powerpoint';
export const PPTX_MIME_TYPE =
	'application/vnd.openxmlformats-officedocument.presentationml.presentation';
export const XLS_MIME_TYPE = 'application/vnd.ms-excel';
export const XLSX_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const CSV_MIME_TYPE = 'text/csv';
export const DOC_MIME_TYPE = 'application/msword';
export const DOCX_MIME_TYPE =
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const TXT_MIME_TYPE = 'text/plain';

export const ODT_MIME_TYPE = 'application/vnd.oasis.opendocument.text';
export const states = {
	AL: 1,
	Alabama: 1,
	AK: 2,
	Alaska: 2,
	AZ: 3,
	Arizona: 3,
	AR: 4,
	Arkansas: 4,
	CA: 5,
	California: 5,
	CO: 6,
	Colorado: 6,
	CT: 7,
	Connecticut: 7,
	DE: 8,
	Delaware: 8,
	FL: 9,
	Florida: 9,
	GA: 10,
	Georgia: 10,
	HI: 11,
	Hawaii: 11,
	ID: 12,
	Idaho: 12,
	IL: 13,
	Illinois: 13,
	IN: 14,
	Indiana: 14,
	IA: 15,
	Iowa: 15,
	KS: 16,
	Kansas: 16,
	KY: 17,
	Kentucky: 17,
	LA: 18,
	Louisiana: 18,
	ME: 19,
	Maine: 19,
	MD: 20,
	Maryland: 20,
	MA: 21,
	Massachusetts: 21,
	MI: 22,
	Michigan: 22,
	MN: 23,
	Minnesota: 23,
	MS: 24,
	Mississippi: 24,
	MO: 25,
	Missouri: 25,
	MT: 26,
	Montana: 26,
	NE: 27,
	Nebraska: 27,
	NV: 28,
	Nevada: 28,
	NH: 29,
	'New Hampshire': 29,
	NJ: 30,
	'New Jersey': 30,
	NM: 31,
	'New Mexico': 31,
	NY: 32,
	'New York': 32,
	NC: 33,
	'North Carolina': 33,
	ND: 34,
	'North Dakota': 34,
	OH: 35,
	Ohio: 35,
	OK: 36,
	Oklahoma: 36,
	OR: 37,
	Oregon: 37,
	PA: 38,
	Pennsylvania: 38,
	RI: 39,
	'Rhode Island': 39,
	SC: 40,
	'South Carolina': 40,
	SD: 41,
	'South Dakota': 41,
	TN: 42,
	Tennessee: 42,
	TX: 43,
	Texas: 43,
	UT: 44,
	Utah: 44,
	VT: 45,
	Vermont: 45,
	VA: 46,
	Virginia: 46,
	WA: 47,
	Washington: 47,
	WV: 48,
	'West Virginia': 48,
	WI: 49,
	Wisconsin: 49,
	WY: 50,
	Wyoming: 50,
	DC: 51,
	'Washington DC': 51,
};

export const IS_PREPARE_OPEN = 'IS_PREPARE_OPEN';
export const IS_DOCUMENTATION_OPEN = 'IS_DOCUMENTATION_OPEN';
export const RELOAD_FETCHED_LIST = 'RELOAD_FETCHED_LIST';
export const UPDATE_LOCAL_STORAGE = 'UPDATE_LOCAL_STORAGE';

export const MODAL_TO_SHOW = 'MODAL_TO_SHOW';

export const SCHOOL_YEAR_CUTOFF_MONTH = 7;
export const SCHOOL_YEAR_CUTOFF_DAY = 1;

export const MAX_FILE_UPLOADER_SIZE = 20;

export const CALLLIST_ROUTES = {
	LISTINGV2: '/call-list-v2',
	CONTACTSV2: '/contacts-v2/',
	CONTACTSV1: '/contacts/',
};

export const DOCUMENTATION_ROUTES = [
	CALLLIST_ROUTES.LISTINGV2,
	'/call-list',
	'/emscomingsoon/callLists',
	'/flipcharts',
	'/comingsoon/flipcharts',
	'/binders',
	'/comingsoon/binders',
];

export const ACCESS_SETTINGS_MODULES = { DRILL_LOGS: 'drill_logs', SAFETY_PLAN: 'safety_plans' };

export const SITE_MAINTENANCE_MODE = false;

export const WELL_PROPORTIONED_AREA = 8560420;
