import React from 'react';
import PropTypes from 'prop-types';
import { Carousel as BSC } from 'react-bootstrap';
import { Icon } from '@blueprintjs/core';
import './Carousel.scss';

/**
 * Nav360 Design System Carousel component
 * ============================================================================
 *
 * This is a light wrapper around bootstrap carousel, and we can swap out as
 * needed, but the shape we expect is: 1 child = 1 slide, and any extra props
 * passed to the component get passed to the React Bootstrap Carousel component,
 * allowing for customization according to its documentation here:
 *
 * https://react-bootstrap.netlify.app/docs/components/carousel/#carousel
 *
 * Example override props include:
 *  - Callbacks for actions: onSlide(start)/onSlid(end)
 *  - Boolean Flags for controls: indicators, controls, fade, slide
 *  - Icon customization: prevIcon, nextIcon (directly rendered element)
 *  - interval: set the time between slides
 *  - as: customize the wrapper element type with a component of your choosing
 *
 */
export const Carousel = ({ children, ...overrideProps }) => {
	const carouselProps = {
		prevLabel: '',
		nextLabel: '',
		prevIcon: <Icon icon="chevron-left" />,
		nextIcon: <Icon icon="chevron-right" />,
		...overrideProps,
	};

	return (
		<div className="ems-carousel">
			<BSC {...carouselProps}>
				{children.map((child, index) => (
					<BSC.Item key={index}>{child}</BSC.Item>
				))}
			</BSC>
		</div>
	);
};

Carousel.propTypes = {
	children: PropTypes.any,
};
