import React, { useState, useEffect, useContext } from 'react';
import { DrillsService } from 'services';
import PropTypes from 'prop-types';
import {
	Dropdown,
	FormModal,
	ToasterContext,
	DateTimePicker,
	Intent,
	FeaturedIcon,
	getDateTimePickerRange,
} from 'componentsV2';
import { getStateId } from '../Dashboard/utils';
import { DrillDistrictRequirementsService } from 'services';
import { sortDrills } from 'utilities';
import { getCurrentSchoolYear } from 'utilities/dates';

export const AddNewDrillModal = ({
	addModalOpen,
	setAddModalOpen,
	siteData,
	yearsData,
	reloadFetchDrills,
}) => {
	const [selectedSiteValue, setSelectedSiteValue] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedDrillType, setSelectedDrillType] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [activeDrillTypes, setActiveDrillTypes] = useState([]);
	const [dropdownLabel, setDropdownLabel] = useState('Select a drill type');
	const [isSiteDropdownDisabled, setIsSiteDropdownDisabled] = useState(false);
	const [minDateValue, setMinDateValue] = useState();
	const [maxDateValue, setMaxDateValue] = useState();
	const [initialMonthValue, setInitialMonthValue] = useState();

	useEffect(() => {
		if (selectedYear) {
			fetchDrillTypesData();
			setAddDrillDateRange(selectedYear.value);
		}
	}, [selectedSiteValue, selectedYear]);

	const setAddDrillDateRange = startYear => {
		if (startYear === getCurrentSchoolYear()[0]) {
			// If the selected year is current year, then we want to display the current month/day.
			const initialMonth = new Date();
			initialMonth.setHours(12, 0, 0, 0); // Setting initial time to 12PM.
			setInitialMonthValue(initialMonth);
		}
		const { min, max } = getDateTimePickerRange(startYear);
		setMinDateValue(min);
		setMaxDateValue(max);
	};

	const fetchDrillTypesData = async () => {
		await DrillDistrictRequirementsService.getAllRequirements(selectedYear.value, getStateId())
			.then(reqs => {
				const reqsArray = [];
				reqs.map(req => {
					req?.districtRequirement?.active &&
						reqsArray.push({
							id: req.id,
							name: req.name,
							label: req.name,
							uuid: req.uuid,
							value: req.id,
						});
				});
				setIsSiteDropdownDisabled(false);
				setActiveDrillTypes(sortDrills(reqsArray));
				if (reqsArray.length < 1) {
					setDropdownLabel(
						'No district requirements have been set for the selected school year.',
					);
					setIsSiteDropdownDisabled(true);
				}
			})
			.catch(error =>
				toaster('There was an error retrieving the drill types: ', Intent.DANGER),
			);
	};
	// On Save update and/or create drills
	const onHandleConfirm = async () => {
		const newDrills = [
			{
				drillType: {
					value: selectedDrillType ? selectedDrillType.uuid : null,
				},
				date: selectedDate,
			},
		];

		DrillsService.updateAndCreateDrills(
			selectedSiteValue?.value,
			selectedYear.value,
			[],
			newDrills,
		)
			.then(response => {
				if (response) {
					reloadFetchDrills();
					setAddModalOpen(false);
					toaster(
						`You have successfully scheduled "${
							selectedDrillType.label
						}" as a new drill.`,
						Intent.SUCCESS,
						<FeaturedIcon icon={'tick'} type={'Success'} />,
					);
				}
			})
			.catch(err => {
				console.log('Error creating drill', err);
				toaster(
					'Error creating new drill',
					Intent.DANGER,
					<FeaturedIcon icon={'error'} type={'Error'} />,
				);
			});
	};

	// Site
	const onHandleSiteChange = item => {
		setSelectedSiteValue(item);
	};

	// School Year
	const onHandleDrillYear = item => {
		setSelectedYear(item);
	};

	// Type
	const onHandleTypeChange = item => {
		setSelectedDrillType(item);
	};

	// Date/Time
	const onHandleDateChange = item => {
		setSelectedDate(item);
	};

	const toasterRef = React.createRef();
	const toaster = useContext(ToasterContext);

	return (
		<>
			<FormModal
				isOpen={addModalOpen}
				onClose={setAddModalOpen}
				showCloseButton
				title="Add new drill"
				scrollContent={false}
				confirmBtn={{
					title: 'Save',
					onClick: () => onHandleConfirm(),
					disabled: !(
						selectedSiteValue &&
						selectedYear &&
						selectedDrillType &&
						selectedDate
					),
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: () => setAddModalOpen(false),
				}}
			>
				<div className="w-100">
					<Dropdown
						label="Site"
						options={siteData}
						isRequired
						value={selectedSiteValue}
						onChange={item => {
							onHandleSiteChange(item);
						}}
					/>
				</div>
				<div className="w-100">
					<Dropdown
						label="School Year"
						options={yearsData}
						isRequired
						value={selectedYear}
						onChange={item => {
							onHandleDrillYear(item);
						}}
					/>
				</div>
				<div className="w-100">
					<Dropdown
						label="Type"
						options={activeDrillTypes}
						isRequired
						placeholder={dropdownLabel}
						disabled={isSiteDropdownDisabled}
						value={selectedDrillType}
						onChange={item => {
							onHandleTypeChange(item);
						}}
					/>
				</div>
				<div className="w-100">
					<DateTimePicker
						initialValue={selectedDate}
						onSubmit={value => onHandleDateChange(value)}
						label="Drill scheduled date"
						labelRequired={true}
						minDate={minDateValue}
						maxDate={maxDateValue}
						initialMonth={initialMonthValue}
					/>
				</div>
			</FormModal>
		</>
	);
};

AddNewDrillModal.propTypes = {
	addModalOpen: PropTypes.any,
	setAddModalOpen: PropTypes.any,
	drillTypesData: PropTypes.any,
	siteData: PropTypes.any,
	yearsData: PropTypes.any,
	reloadFetchDrills: PropTypes.func,
};
