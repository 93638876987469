const isStringUrl = str => {
	// Removing regex to check string url as it is complex and uses heavy
	// backtracking that caused performance issue for large number of variable fields that are in a safety plan.
	// Using the built-in javascript validation to check if a string is URL.
	// As this is a browser API validation it optimizes the performance and avoids issues such as webpage freezing.
	try {
		new URL(str);
		return true;
	} catch (error) {
		return false;
	}
};

const removeVariables = (content, variableValues) => {
	let tempContent = content;
	variableValues.forEach(item => {
		if (isStringUrl(item.variableValue)) {
			tempContent = tempContent.replaceAll(
				`<div><img src="${item.variableValue}" style="width: auto; height: 300px"/></div>`,
				`<span class="variable-highlight">${item.variableName}</span>`,
			);
		}
		tempContent = tempContent.replaceAll(
			`<span class="variable-value-highlight" contenteditable="false">${
				item.variableValue
			}</span>`,
			`<span class="variable-highlight">${item.variableName}</span>`,
		);
	});
	return tempContent;
};

export { removeVariables };
