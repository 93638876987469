import React, { useEffect, useState } from 'react';
import { Callout } from 'componentsV2';
import PropTypes from 'prop-types';
import './css/DeleteFirstResponderModal.scss';
import { checkPermission } from 'utilities/permissions';
import { useSelector } from 'react-redux';
import { FormModal } from 'componentsV2';
import { FirstResponderUnsavedChangesModal } from './FirstResponderUnsavedChangesModal';

const DeleteFirstResponderModal = ({
	setModalToShow,
	DeleteFirstResponderAction,
	firstResponder,
}) => {
	const [sitesList, setSitesList] = useState([]);
	const [listUpdated, setListUpdated] = useState(false);
	const [isTouched, setIsTouched] = useState(false);
	const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true);
	const [removeAllChecked, setRemoveAllChecked] = useState(false);
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
	const permissions = useSelector(state => state.route.permissions);

	useEffect(() => {
		var sites = [];
		firstResponder.allowed_sites_ids.map((alowedSiteId, key) => {
			if (checkPermission('user_edit', permissions, alowedSiteId)) {
				sites.push({
					id: alowedSiteId,
					name: firstResponder.allowed_sites[key],
					isSelected: false,
				});
			}
		});
		setSitesList(sites);
	}, []);

	const onRemoveAllSitesHandler = () => {
		setSitesList(oldValues => {
			let currentSites = oldValues;
			currentSites.map(currentSite => {
				currentSite.isSelected = !removeAllChecked;
			});
			return currentSites;
		});
		setRemoveAllChecked(oldValue => {
			if (!oldValue) {
				setConfirmBtnDisabled(false);
			} else {
				setConfirmBtnDisabled(true);
			}
			return !oldValue;
		});
		setIsTouched(true);
	};

	const onRemoveSiteHandler = siteId => {
		var trueCounter = 0;
		setSitesList(oldValues => {
			let currentSites = oldValues;
			currentSites.map(currentSite => {
				//if the current site id equal to siteId it should inverse the current site value
				if (currentSite.id === siteId) {
					currentSite.isSelected = !currentSite.isSelected;
				}

				//counting true values in order to use it in setting removeAllChecked state
				if (currentSite.isSelected) {
					trueCounter++;
				}
			});
			if (trueCounter === oldValues.length) {
				setRemoveAllChecked(true);
				setConfirmBtnDisabled(false);
			} else if (trueCounter === 0) {
				setRemoveAllChecked(false);
				setConfirmBtnDisabled(true);
			} else {
				setConfirmBtnDisabled(false);
				if (removeAllChecked) {
					setRemoveAllChecked(false);
				}
			}
			return currentSites;
		});

		setListUpdated(oldValue => !oldValue);
		setIsTouched(true);
	};

	const onConfirmActionHandler = () => {
		DeleteFirstResponderAction(sitesList);
	};

	const onCancelActionHandler = () => {
		if (isTouched) {
			setShowUnsavedChangesModal(true);
		} else {
			setModalToShow(false);
		}
	};

	const onUnsavedModalConfrimActionHandler = () => {
		setModalToShow(false);
		setShowUnsavedChangesModal(false);
	};

	return (
		<>
			{showUnsavedChangesModal && (
				<FirstResponderUnsavedChangesModal
					onUnsavedModalConfrimActionHandler={onUnsavedModalConfrimActionHandler}
					setShowUnsavedChangesModal={setShowUnsavedChangesModal}
				/>
			)}
			<FormModal
				isOpen
				onClose={onCancelActionHandler}
				showCloseButton
				title="Remove First Responder Agency"
				scrollContent={false}
				confirmBtn={{
					title: 'Confirm',
					onClick: onConfirmActionHandler,
					disabled: confirmBtnDisabled,
				}}
				cancelBtn={{
					title: 'Cancel',
					onClick: onCancelActionHandler,
				}}
			>
				<div className="container delete-first-responder-container">
					<div className="row">
						<div className="col-md-12">
							<Callout
								intent="primary"
								icon="info-sign"
								text="Remove this first responder agency's access to one or more sites"
							/>
							<RemoveLink
								siteName="Remove from all sites"
								isSelected={removeAllChecked}
								onDeleteAction={onRemoveAllSitesHandler}
							/>
							<hr className="hr-container-delete-first-responder" />
							<RemoveLink siteName="Remove from specific sites" />
							{sitesList.map(site => (
								<RemoveLink
									key={site.id}
									siteName={site.name}
									isSelected={site.isSelected}
									onDeleteAction={() => onRemoveSiteHandler(site.id)}
								/>
							))}
						</div>
					</div>
				</div>
			</FormModal>
		</>
	);
};

const RemoveLink = ({ siteName, onDeleteAction, isSelected, textStyle }) => (
	<div className="first-responder-remove-link d-flex justify-content-between">
		<p style={textStyle}>{siteName}</p>
		{onDeleteAction && (
			<a
				onClick={onDeleteAction}
				style={{ color: isSelected ? 'var(--grey-color)' : 'var(--primary)' }}
			>
				{isSelected ? 'Undo' : 'Remove'}
			</a>
		)}
	</div>
);

RemoveLink.propTypes = {
	siteName: PropTypes.string.isRequired,
	onDeleteAction: PropTypes.func,
	isSelected: PropTypes.bool,
	textStyle: PropTypes.object,
};

DeleteFirstResponderModal.propTypes = {
	loading: PropTypes.bool.isRequired,
	setModalToShow: PropTypes.func.isRequired,
	DeleteFirstResponderAction: PropTypes.func.isRequired,
	firstResponder: PropTypes.object.isRequired,
};

export default DeleteFirstResponderModal;
