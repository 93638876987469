import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { BuildingsService, SchoolYearsService, DrillLogService, DrillsService } from 'services';
import {
	EmptyState,
	Button,
	ActionsButton,
	emptyFolder,
	ConfirmationModal,
	FeaturedIcon,
	ToasterContext,
	Intent,
	AlertModal,
	LoadingSpinner,
	LoadingModal,
} from 'componentsV2';
import { checkPermission } from 'utilities/permissions';
import { useSelector } from 'react-redux';
import { getCurrentSchoolYear } from 'utilities/dates';
import { AddNewDrillModal } from '../AddNewDrillModal/AddNewDrillModal';
import { ScheduleDrillModal } from '../ScheduleDrillModal/ScheduleDrillModal';
import { DrillsTable } from './DrillsTable';
import './Dashboard.scss';

export const Dashboard = ({ history }) => {
	const [addModalOpen, setAddModalOpen] = useState(false);
	const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
	const [site, setSite] = useState(null);
	const [years, setYears] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isFilterLoading, setIsFilterLoading] = useState(false);
	const [drills, setDrills] = useState([]);
	const [drillID, setDrillID] = useState(null);
	const [drillData, setDrillData] = useState(null);
	const [modalToShow, setModalToShow] = useState(false);
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [isDrillsReportDownloading, setIsDrillsReportDownloading] = useState(false);
	const [isCompletedDrillsDownloading, setIsCompletedDrillsDownloading] = useState(false);
	const userDetails = JSON.parse(localStorage.getItem('loggedUserData'));
	const date = new Date().toLocaleDateString();
	const buildingName = userDetails.primaryBuilding.name.split(' ').join('');
	const drillsReportFileName = `${buildingName}_${date}_Drills`;
	const drillsFilterationData = useSelector(state => state.route.drillsFilterationData);
	const [filtrationData, setFiltrationData] = useState(drillsFilterationData);

	const toaster = useContext(ToasterContext);

	const permissions = useSelector(state => state.route.permissions);
	const canEditSchedules = checkPermission('drilllogs_schedule', permissions);
	const filtrationDataLength = Object.keys(drillsFilterationData).length;

	useEffect(() => {
		fetchDrills();
		getDrillSites();
		getDrillYears();
	}, []);

	useEffect(() => {
		fetchDrills();
	}, [filtrationData]);

	const fetchDrills = async () => {
		await DrillsService.getWithPostParams(filtrationData)
			.then(resp => {
				setDrills(resp.data);
				setTotalRows(resp._metadata.totalRecords);
			})
			.catch(console.error)
			.finally(() => setIsLoading(false));
	};

	const onCloseModal = () => {
		setDrillID(null);
		setDrillData(null);
		setIsModalLoading(false);
		setModalToShow(false);
	};

	const loadDeleteModal = drill => {
		setDrillID(drill.scheduleId);
		setDrillData(drill);
		setEditMode(false);
		setModalToShow('DeleteCustomDrillModal');
	};

	const deleteDrill = async () => {
		setIsModalLoading(true);
		await DrillLogService.removeDrillLog(drillID)
			.then(res => {
				if (res.statusCode === 200) {
					setModalToShow(false);
					setIsModalLoading(false);
					toaster(
						`You have successfully deleted "${drillData.type}"`,
						Intent.NONE,
						<FeaturedIcon icon="trash" type="Gray" />,
					);
				} else {
					setModalToShow(false);
					setIsModalLoading(false);
					toaster(
						`Drill Log "${drillData.type}" was not deleted`,
						Intent.DANGER,
						<FeaturedIcon icon="error" type="Error" />,
					);
				}
			})
			.catch(err => {
				setModalToShow(false);
				setIsModalLoading(false);
				console.log('error', err);
				toaster(
					err?.error?.description,
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
			});

		setIsLoading(true);
		const newDrills = drills.filter(drill => drill.scheduleId !== drillID);
		setDrills(newDrills);
		setIsLoading(false);
	};

	const addDrillToCalendar = drill => {
		DrillLogService.addDrillLogToCalendar(drill, drill.year, drill.buildingId).catch(err => {
			console.log('error', err);
			toaster(
				err?.error?.description,
				Intent.DANGER,
				<FeaturedIcon icon="error" type="Error" />,
			);
		});
	};

	const loadInstructionsModal = drill => {
		setDrillID(drill.scheduleId);
		setDrillData(drill);
		setModalToShow('ShowInstructionsModal');
	};

	const getDrillSites = () => {
		BuildingsService.getAll().then(siteDrillTypes => {
			const enabledSites = siteDrillTypes.filter(site => site.drillLogsEnabled == true);
			setSite(enabledSites);
		});
	};

	const getDrillYears = () => {
		const currentSchoolYear = getCurrentSchoolYear()[0];
		SchoolYearsService.fetchYears().then(siteYears => {
			const filteredYears = siteYears?.filter(year => year.value <= currentSchoolYear);
			setYears(filteredYears);
		});
	};

	const manageReminders = () => {
		history.push(`/drillsV2/reminders`);
	};

	const handleDrillsReportDownload = async () => {
		setIsDrillsReportDownloading(true);
		// removing the keys perPage and page from filteration data obj
		// and creating a new one to pass in to download drills report
		const { page, perPage, ...newFilterationData } = filtrationData;
		await DrillsService.downloadDrillsReport(newFilterationData)
			.then(resp => {
				const url = window.URL.createObjectURL(new Blob([resp]));
				var a = document.createElement('a');
				a.href = url;
				a.download = `${drillsReportFileName} - .xlsx`;
				a.click();
				a.remove();
			})
			.catch(err => {
				toaster(
					'There was an error downloading the report. Please try again.',
					Intent.DANGER,
					<FeaturedIcon icon="error" type="Error" />,
				);
				console.log(err);
			})
			.finally(() => setIsDrillsReportDownloading(false));
	};

	const handleDrillsCompletedDownload = () => {
		setIsCompletedDrillsDownloading(true);
		const { page, perPage, ...restFilterationData } = drillsFilterationData;
		const updatedDrillsFilterationData = { ...restFilterationData, status: ['completed'] };
		DrillsService.exportCompletedDrills(updatedDrillsFilterationData)
			.then(pdfBlob => {
				const filename = `${drillsReportFileName}`;
				const data = new Blob([pdfBlob], {
					type: 'application/pdf',
				});
				const csvURL = window.URL.createObjectURL(data);
				const tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', `${filename}.pdf`);
				tempLink.click();
				return Promise.resolve();
			})
			.finally(() => setIsCompletedDrillsDownloading(false));
	};

	if (isLoading) {
		return <LoadingSpinner />;
	}

	// we also need to check every property of drillsFilterationData if there are no drills. Also, we know that the default
	// values for filtrationData will be having 5 properties (according to our filters).
	// drillsFilterationData length is checked here which equals to 5 as we are having 5 keys in the defaultValues object added,
	// if the keys in defaultValues (on DrillsTable) obj change we will need to change the integer value that is passed in the if statement.
	if (
		!isFilterLoading &&
		drills.length === 0 &&
		filtrationDataLength === 5 &&
		drillsFilterationData?.years?.includes(getCurrentSchoolYear()[0]) &&
		drillsFilterationData?.searchByType === ''
	) {
		return (
			<>
				<EmptyState
					header="No drills were added yet"
					description="Begin by adding drills to view them in the list"
					icon={emptyFolder}
				>
					{canEditSchedules && (
						<>
							<Button
								icon="plus"
								text="New drill"
								onClick={() => setAddModalOpen(true)}
							/>
							<Button
								text="Schedule and log"
								type="secondaryDefault"
								onClick={() => setScheduleModalOpen(true)}
							/>
						</>
					)}
				</EmptyState>
				{/* Add New Drill Modal */}
				{addModalOpen && site && years && (
					<AddNewDrillModal
						addModalOpen={addModalOpen}
						setAddModalOpen={setAddModalOpen}
						siteData={site}
						yearsData={years}
						reloadFetchDrills={fetchDrills}
					/>
				)}
				{/* Schedule and Log Modal */}
				{scheduleModalOpen && (
					<ScheduleDrillModal
						scheduleModalOpen={scheduleModalOpen}
						setScheduleModalOpen={setScheduleModalOpen}
						scheduleSiteData={site}
						scheduleYearsData={years}
						reloadFetchDrills={fetchDrills}
					/>
				)}
			</>
		);
	}
	return (
		<>
			{modalToShow === 'DeleteCustomDrillModal' && !editMode && (
				<ConfirmationModal
					isOpen
					title={`Are you sure you want to delete "${drillData.type}"?`}
					subtitle="Once deleted, this cannot be recovered."
					showCloseButton={false}
					icon={{ icon: 'trash', iconColor: 'error' }}
					onClose={onCloseModal}
					cancelBtn={{
						title: 'No, go back',
						onClick: onCloseModal,
					}}
					confirmBtn={{
						title: 'Yes, delete it',
						onClick: deleteDrill,
						type: 'primaryDanger',
					}}
					loading={isModalLoading}
				/>
			)}
			{modalToShow === 'ShowInstructionsModal' && (
				<AlertModal
					isOpen
					showCloseButton={true}
					title={drillData.type}
					customClassName={'drill-instructions-modal'}
					subtitle={
						drillData.schedulingInstructions && drillData.postingInstructions
							? `${drillData.schedulingInstructions} ${drillData.postingInstructions}`
							: drillData.schedulingInstructions
							? drillData.schedulingInstructions
							: drillData.postingInstructions
							? drillData.postingInstructions
							: 'No instructions found.'
					}
					respectSubtitleHtml={true}
					onClose={onCloseModal}
				/>
			)}
			<div className="d-flex flex-column">
				<div className="Dashboard-Header">
					<h4 className="title">Drills list</h4>
					<div className="actions">
						{canEditSchedules && (
							<>
								<Button
									icon="plus"
									intent="default"
									text="New drill"
									type="primaryDefault"
									onClick={() => setAddModalOpen(true)}
								/>
								<Button
									intent="default"
									text="Schedule and log"
									type="secondaryDefault"
									onClick={() => setScheduleModalOpen(true)}
								/>
								<ActionsButton>
									<Button
										text="Manage reminders"
										type="ghostDefault"
										wrapperClass="displayBlock"
										className="actionButtonElement"
										onClick={() => manageReminders()}
									/>
									<Button
										text="Download Drills List"
										type="ghostDefault"
										wrapperClass="displayBlock"
										className="actionButtonElement"
										onClick={() => handleDrillsReportDownload()}
									/>
									<Button
										text="Download Completed Logs"
										type="ghostDefault"
										wrapperClass="displayBlock"
										className="actionButtonElement"
										onClick={() => {
											handleDrillsCompletedDownload();
										}}
									/>
								</ActionsButton>
							</>
						)}
					</div>
				</div>
				<DrillsTable
					history={history}
					drills={drills}
					setDrills={setDrills}
					totalRows={totalRows}
					filtrationData={filtrationData}
					setFiltrationData={setFiltrationData}
					setIsFilterLoading={setIsFilterLoading}
					setModalToShow={setModalToShow}
					loadDeleteModal={loadDeleteModal}
					loadInstructionsModal={loadInstructionsModal}
					addDrillToCalendar={addDrillToCalendar}
				/>
			</div>
			{isDrillsReportDownloading && (
				<LoadingModal isOpen={isDrillsReportDownloading} loading text="Downloading" />
			)}
			{isCompletedDrillsDownloading && (
				<LoadingModal
					isOpen={isCompletedDrillsDownloading}
					loading
					text="Report is being generated…"
					subText="This may take a few minutes."
				/>
			)}
			{/* Add New Drill Modal Dialog */}
			{addModalOpen && site && years && (
				<AddNewDrillModal
					addModalOpen={addModalOpen}
					setAddModalOpen={setAddModalOpen}
					siteData={site}
					yearsData={years}
					reloadFetchDrills={fetchDrills}
				/>
			)}
			{/* Schedule and Log Modal Dialog */}
			{scheduleModalOpen && (
				<ScheduleDrillModal
					scheduleModalOpen={scheduleModalOpen}
					setScheduleModalOpen={setScheduleModalOpen}
					scheduleSiteData={site}
					scheduleYearsData={years}
					reloadFetchDrills={fetchDrills}
					history={history}
				/>
			)}
		</>
	);
};

Dashboard.propTypes = {
	history: PropTypes.any,
};
