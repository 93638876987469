import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
	checkPermission,
	filterSitesPerPermissionCode,
	checkPermissionOnDistrict,
} from 'utilities/permissions';
import {
	ContentSwitcher,
	LoadingSpinner,
	ConfirmationModal,
	FeaturedIcon,
	ToasterContext,
	Intent,
	FilterMenu,
	FilterMenuFn,
	FilterBar,
} from 'componentsV2';
import AddEditCustomScenarioModal from 'components/Modals/AddEditCustomScenarioModal';
import { TabHeader } from './TabHeader';
import { ScenariosTable } from './ScenariosTable';
import { HowToUseModal } from './HowToUseModal';
import './css/Scenarios.scss';
import { DrillsScenariosService } from 'services/DrillsScenariosService';
import { DrillLogService } from 'services/DrillLogService';
import { BuildingsService } from 'services/BuildingsService';
import { ScenariosCategoriesService } from 'services/ScenariosCategoriesService';
import { SitesDropdownMultiSelect } from 'components/SitesDropdownMultiSelect';
import { SearchBar } from 'components/SearchBar';
import { ACCESS_SETTINGS_MODULES } from 'utilities/constants';

export const Scenarios = ({ history }) => {
	const tabs = [
		{ id: 'all', label: 'View All' },
		{ id: 'used', label: 'Used' },
		{ id: 'unused', label: 'Unused' },
	];
	const [scenarios, setScenarios] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [scenarioID, setScenarioID] = useState(null);
	const [scenarioData, setScenarioData] = useState(null);
	const [modalToShow, setModalToShow] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [buildings, setBuildings] = useState([]);
	const [categories, setCategories] = useState([{}]);
	const [selectedSites, setSelectedSites] = useState([]);

	const toaster = useContext(ToasterContext);
	const permissions = useSelector((state) => state.route.permissions);
	const editPermission = checkPermission('drill_scenario_edit', permissions);
	const hasPermissionOnDistrict = checkPermissionOnDistrict('drill_scenario_edit');

	const onCloseModal = () => {
		setScenarioID(null);
		setIsModalLoading(false);
		setModalToShow(false);
	};

	const loadAddModal = () => {
		setScenarioID(null);
		setEditMode(false);
		setModalToShow('AddEditCustomScenarioModal');
	};

	const loadEditModal = (id) => {
		setScenarioID(id);
		setEditMode(true);
		setModalToShow('AddEditCustomScenarioModal');
	};

	const loadDeleteModal = (drillScenario) => {
		setScenarioID(drillScenario.id);
		setScenarioData(drillScenario);
		setEditMode(false);
		setModalToShow('DeleteCustomScenarioModal');
	};

	const loadHowToUseModal = () => {
		setModalToShow('HowToUseModal');
	};

	// This formats any mult-selectable option for the request URL
	const createQueryParamArray = (parameterName, values) => {
		let query = '';
		for (const value of values) {
			query += `&${parameterName}[]=${value}`;
		}
		return query;
	};

	const filterationDataReducer = (filterationData, action) => {
		switch (action.type) {
			case 'title':
				filterationData.title = action.payload;
				break;
			case 'site_id':
				if (action.payload.value === 'all') {
					filterationData[action.type] = 'all';
				} else {
					filterationData[action.type] = action.payload.id;
				}
				break;
			case 'filter':
				filterationData.category = action.payload.category;
				filterationData.isDisabled = action.payload.isDisabled;
				filterationData.onlyAudio = action.payload.onlyAudio;
				filterationData.onlyVideo = action.payload.onlyVideo;
				break;
			case 'buildingIds':
				if (action.payload.length > 0) {
					const queryParamArray = createQueryParamArray('buildingIds', action.payload);
					filterationData.buildingIds = queryParamArray;
				} else {
					filterationData.buildingIds = '';
				}
				break;
			default:
				filterationData[action.type] = action.payload;
				break;
		}
		return {
			page: filterationData.page,
			perPage: filterationData.perPage,
			site_id: filterationData.site_id,
			title: filterationData.title,
			lastUsed: filterationData.lastUsed,
			sortKey: filterationData.sortKey,
			sortDir: filterationData.sortDir,
			category: filterationData.category,
			isDisabled: filterationData.isDisabled,
			onlyAudio: filterationData.onlyAudio,
			onlyVideo: filterationData.onlyVideo,
			buildingIds: filterationData.buildingIds,
		};
	};
	const [filterationData, dispatchFilterationData] = useReducer(filterationDataReducer, {
		page: 1,
		perPage: 10,
		site_id: 'all',
		title: null,
		lastUsed: 'all',
		sortKey: 'title',
		sortDir: 'asc',
		category: { label: 'all', value: 'all' },
		isDisabled: false,
		onlyAudio: false,
		onlyVideo: false,
		buildingIds: '',
	});

	const deleteScenario = async () => {
		setIsModalLoading(true);
		await DrillLogService.removeDrillLogScenario(scenarioID)
			.then((res) => {
				if (res.statusCode == 200) {
					setModalToShow(false);
					fetchScenarios();
					setIsModalLoading(false);
					toaster(
						'You have successfully deleted "' + scenarioData.title + '"',
						Intent.NONE,
						<FeaturedIcon icon={'trash'} type={'Gray'} />,
					);
				} else {
					setModalToShow(false);
					setIsModalLoading(false);
					toaster(
						'Scenario "' + scenarioData.title + '" was not deleted',
						Intent.DANGER,
						<FeaturedIcon icon={'error'} type={'Error'} />,
					);
				}
			})
			.catch((err) => {
				setModalToShow(false);
				setIsModalLoading(false);
				toaster(
					err?.error?.description,
					Intent.DANGER,
					<FeaturedIcon icon={'error'} type={'Error'} />,
				);
			});
	};

	const fetchScenarios = () => {
		DrillsScenariosService.getAll(filterationData)
			.then((resp) => {
				if (resp.statusCode === 200) {
					setTotalRows(resp.data.metadata.totalRecords);
					setScenarios(resp.data.records);

					if (resp.data.metadata.totalRecords === 0) {
						setNoScenarioFound(true);
					} else {
						setNoScenarioFound(false);
					}
				}
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const fetchBuildings = () => {
		BuildingsService.getAll(false, false, ACCESS_SETTINGS_MODULES.DRILL_LOGS).then(
			(serviceBuildings) => {
				let sites = filterSitesPerPermissionCode(
					'drill_scenario_edit',
					permissions,
					serviceBuildings,
				);
				if (hasPermissionOnDistrict) {
					sites.unshift({ label: 'Visible to all', value: null });
				}
				setBuildings(sites);
			},
		);
	};

	const fetchCategories = async () => {
		const categoriesInfo = await ScenariosCategoriesService.getAll();
		const categoryList = categoriesInfo.data.map((item) => ({
			label: item.title,
			value: item.id,
		}));
		setCategories(categoryList);
	};

	const selectUsedState = (val) => {
		dispatchFilterationData({ type: 'lastUsed', payload: val });
	};

	useEffect(() => {
		fetchScenarios();
	}, [filterationData]);

	useEffect(() => {
		fetchBuildings();
		fetchCategories().then();
	}, []);

	// FILTER MENU
	const { filterData, handleChangeFilter, removeFilter, getFilterValue } = FilterMenuFn();

	const handleChangeFilterWrapper = (filterName, value, filterType) => {
		handleChangeFilter(filterName, value, filterType);
		const { filters } = filterData;
		if ('mediaType' in filters) {
			const { mediaType } = filters;
			const mediaTypeOptions = ['isDisabled', 'onlyAudio', 'onlyVideo'];
			for (const option of mediaTypeOptions) {
				option === mediaType
					? dispatchFilterationData({ type: option, payload: true })
					: dispatchFilterationData({ type: option, payload: false });
			}
		}
		if ('categories' in filters) {
			const payloadValue = createQueryParamArray('categories', filters.categories);
			dispatchFilterationData({
				type: 'category',
				payload: { label: 'select', value: payloadValue },
			});
		} else {
			dispatchFilterationData({ type: 'category', payload: { label: 'all', value: 'all' } });
		}
	};

	const removeFilterWrapper = (filterName) => {
		// remove filters from filterationData
		if (filterName === 'mediaType') {
			const mediaTypeOptions = ['isDisabled', 'onlyAudio', 'onlyVideo'];
			for (const option of mediaTypeOptions) {
				dispatchFilterationData({ type: option, payload: false });
			}
		}
		if (filterName === 'categories') {
			dispatchFilterationData({ type: 'category', payload: { label: 'all', value: 'all' } });
		}
		// remove filters from UI
		removeFilter(filterName);
	};

	const filterMenuItems = [
		{
			title: 'Type',
			name: 'mediaType',
			onchange: handleChangeFilterWrapper,
			options: [
				{ value: 'isDisabled', label: 'Disabled' },
				{ value: 'onlyAudio', label: 'Audio' },
				{ value: 'onlyVideo', label: 'Video' },
			],
		},
		{
			title: 'Category',
			name: 'categories',
			onchange: handleChangeFilterWrapper,
			multipleSelect: true,
			options: categories,
		},
	];

	const onChangeSiteHandler = (sites) => {
		if (sites.length > 0) {
			dispatchFilterationData({
				type: 'buildingIds',
				payload: sites.map((site) => site.value),
			});
		} else {
			dispatchFilterationData({ type: 'buildingIds', payload: [] });
		}
		setSelectedSites(sites);
	};

	const onSearch = (scenarioNameInput) => {
		setTimeout(() => {
			if (scenarioNameInput) {
				dispatchFilterationData({
					type: 'title',
					payload: scenarioNameInput.toLowerCase(),
				});
			} else {
				dispatchFilterationData({ type: 'title', payload: null });
			}
		}, 100);
	};

	if (isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<>
			{modalToShow === 'DeleteCustomScenarioModal' && !editMode && (
				<ConfirmationModal
					isOpen={true}
					title={'Are you sure you want to delete ' + `"` + scenarioData.title + `"?`}
					subtitle={'Once deleted, this cannot be recovered.'}
					showCloseButton={false}
					icon={{ icon: 'trash', iconColor: 'error' }}
					onClose={onCloseModal}
					cancelBtn={{ title: 'No, go back', onClick: onCloseModal }}
					confirmBtn={{
						title: 'Yes, delete it',
						onClick: deleteScenario,
						type: 'primaryDanger',
					}}
					loading={isModalLoading}
				/>
			)}

			{modalToShow === 'AddEditCustomScenarioModal' && editMode && (
				<AddEditCustomScenarioModal
					onCloseModal={onCloseModal}
					editing={true}
					scenarioId={scenarioID}
					updateDataCallBack={fetchScenarios}
					categories={categories}
					buildings={buildings}
				/>
			)}

			{modalToShow === 'AddEditCustomScenarioModal' && !editMode && (
				<AddEditCustomScenarioModal
					onCloseModal={onCloseModal}
					editing={false}
					updateDataCallBack={fetchScenarios}
					categories={categories}
					buildings={buildings}
				/>
			)}

			{modalToShow === 'HowToUseModal' && <HowToUseModal onCloseModal={onCloseModal} />}

			<div className="container-fluid px-0 tab-section">
				<TabHeader
					title="Drill scenarios"
					badgedata={{
						show: true,
						number: totalRows,
						intent: 'number',
					}}
					subtitle="Real-world scenarios to better train and prepare staff in the event of an emergency and improve the overall effectiveness of your school’s safety plan."
					subsubtitle="How to use scenarios with your staff"
					actionButton
					loadAddModal={loadAddModal}
					editPermission={editPermission}
					loadHowToUseModal={loadHowToUseModal}
				/>
				<div className="scenarios-filteration">
					<div className="scenarios-filteration-row1">
						<div className="scenarios-filteration-row1-col1">
							<ContentSwitcher
								onClick={selectUsedState}
								activeTab={filterationData.lastUsed}
								defaultActiveTabs={['all']}
								tabs={tabs}
							/>
						</div>
						<div className="scenarios-filteration-row1-col2">
							<SearchBar
								placeholder="Search by name..."
								icon="search"
								onSearch={onSearch}
							/>
							<SitesDropdownMultiSelect
								label={null}
								value={selectedSites}
								placeholder="Select a Site"
								onChange={onChangeSiteHandler}
								visibleOptions={1}
								labelState={false}
								className="sites-mddl"
								allowSelectAll={false}
								enabledModule={ACCESS_SETTINGS_MODULES.DRILL_LOGS}
							/>
							<FilterMenu items={filterMenuItems} getFilterValue={getFilterValue} />
						</div>
					</div>
					<div className="scenarios-filteration-row2-col1">
						<FilterBar
							items={filterMenuItems}
							getFilterValue={getFilterValue}
							removeFilter={removeFilterWrapper}
						/>
					</div>
				</div>

				<div className="row">
					<ScenariosTable
						scenarios={scenarios}
						filterationData={filterationData}
						dispatchFilterationData={dispatchFilterationData}
						totalRows={totalRows}
						fetchScenarios={fetchScenarios}
						scenarioID
						setScenarioID={setScenarioID}
						loadEditModal={loadEditModal}
						loadDeleteModal={loadDeleteModal}
						setModalToShow={setModalToShow}
						history={history}
					/>
				</div>
			</div>
		</>
	);
};
