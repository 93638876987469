/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { LOCATION_CHANGE } from 'react-router-redux';
import { getCurrentSchoolYear } from 'utilities/dates';
import {
	SET_USER,
	UNSET_USER,
	SET_USER_DATA,
	SET_CURRENT_DISTRICT,
	UPDATE_SUBSECTION,
	REORDER_SECTIONS,
	INDICATOR_RED,
	LOAD_TEMPLATE,
	UPDATE_SECTION_TITLE,
	UPDATE_SUBSECTION_TITLE,
	UPDATE_TEMPLATE_NAME,
	ADD_SUBSECTION,
	ADD_SECTION,
	AUTO_SAVE,
	AUTO_SAVE_INIT,
	UPDATE_SECTION_CONTENT,
	REMOVE_SECTION,
	REMOVE_SECTION_NEW,
	REMOVE_SUBSECTION,
	REMOVE_SUBSECTION_NEW,
	ADD_DOCUMENT_UPLOAD,
	FLIP_REUPLOAD,
	ADD_FILE,
	ADD_VALUE_FILE,
	DELETE_FILE,
	DELETE_VALUE_FILE,
	CLEAR_TEMPLATE_DATA,
	NEXT_SECTION,
	ADD_BONUS_FILES,
	MARK_SECTION_COMPLETE,
	MARK_SUBSECTION_COMPLETE,
	SET_SCROLL_MODE,
	UNSET_SCROLL_MODE,
	REMOVE_BONUS_FILE,
	SET_DISTRICT_ID,
	SET_ROOT_PERMISSIONS,
	AUTO_SAVE_TIME,
	SET_DISTRICT_TOKEN,
	SET_CALL_LIST_SITES,
	SET_CALL_LIST_VIEW_TYPE,
	SET_MASQUERADING_USER_TOKEN,
	SET_MASQUERADING_USER_DATA,
	DISTRICT_PLANS_SITE,
	SET_SELECTED_SITES,
	IS_PREPARE_OPEN,
	IS_DOCUMENTATION_OPEN,
	MODAL_TO_SHOW,
	RELOAD_FETCHED_LIST,
	DOCUMENTATION_ROUTES,
	UPDATE_LOCAL_STORAGE,
	USER_ACCESS_TYPES,
} from 'utilities/constants';

function checkUser() {
	const localStorageToken = localStorage.getItem('user');
	if (localStorageToken) {
		try {
			const token = JSON.parse(localStorageToken).jwt;
			if (token) {
				return token;
			}
			throw new Error('Error parsing token');
		} catch (error) {
			return null;
		}
	}

	const user = {
		jwt: '',
	};
	localStorage.setItem('user', JSON.stringify(user));
	return null;
}

const loadLastStoredValueFor = key => {
	const localStorageItem = localStorage.getItem(key);
	if (localStorageItem) {
		try {
			const item = JSON.parse(localStorageItem);
			if (item) {
				return item;
			}
			throw new Error('Error parsing item');
		} catch (error) {
			return null;
		}
	}
	return null;
};

const checkFirstResponderBuilding = () => {
	// Checking if the user is a first responder.
	const firstResponderUserDetails = JSON.parse(localStorage.getItem('loggedUserData'));
	const isUserFirstResponder =
		firstResponderUserDetails?.accessType === USER_ACCESS_TYPES.FIRST_RESPONDER;
	// If the current user is First responder adding its default accessed site (building) to the object that is passed in
	// to get the drills data for the accessed site. Else the buildingIds is not added by default to normal users.
	if (isUserFirstResponder) {
		return {
			buildingIds: [firstResponderUserDetails?.primaryBuilding?.id],
			years: [getCurrentSchoolYear()[0]],
			sortKey: 'type',
			page: 1,
			perPage: 10,
		};
	} else {
		return {
			years: [getCurrentSchoolYear()[0]],
			sortKey: 'type',
			page: 1,
			perPage: 10,
		};
	}
};

const USER_KEY_STORAGE = 'user';
const USER_DATA_KEY_STORAGE = 'loggedUserData';
const DISTRICT_TOKEN = 'districtToken';
const LAST_SCHOOL_YEAR_KEY = 'lastSelectedSchoolYear';
const LAST_SITE_KEY = 'lastSelectedSite';
const LAST_STATE_KEY = 'lastSelectedState';
const MASQUERADING_USER_TOKEN = 'masqueradingUserToken';
const MASQUERADING_USER_DATA = 'masqueradingUserData';
const HAS_MULTI_DISTRICTS = 'hasMultiDistricts';
const IS_SYS_ADMIN = 'isSysAdmin';
const USER_PERMISSIONS = 'userPermissions';
const CURRENT_DISTRICT_KEY = 'currentDistrict';
const DISTRICT_INFO_KEY = 'distInfo';

const DEFAULT_IDS = {
	core: 1,
	plan: 1000001,
	site: 5000001,
};
const idsStorageDefaultFormat = {
	core: {
		sectionId: DEFAULT_IDS.core,
		subsectionId: DEFAULT_IDS.core,
	},
	plan: {
		sectionId: DEFAULT_IDS.plan,
		subsectionId: DEFAULT_IDS.plan,
	},
	site: {
		sectionId: DEFAULT_IDS.site,
		subsectionId: DEFAULT_IDS.site,
	},
};

const resetLocalStorageValues = () => {
	localStorage.removeItem(USER_KEY_STORAGE);
	localStorage.removeItem(LAST_SCHOOL_YEAR_KEY);
	localStorage.removeItem(LAST_SITE_KEY);
	localStorage.removeItem(LAST_STATE_KEY);
	localStorage.removeItem(MASQUERADING_USER_TOKEN);
	localStorage.removeItem(MASQUERADING_USER_DATA);
	localStorage.removeItem(USER_DATA_KEY_STORAGE);
	localStorage.removeItem(DISTRICT_TOKEN);
	localStorage.removeItem(HAS_MULTI_DISTRICTS);
	localStorage.removeItem(IS_SYS_ADMIN);
	localStorage.removeItem(USER_PERMISSIONS);
	localStorage.removeItem(CURRENT_DISTRICT_KEY);
	localStorage.removeItem(DISTRICT_INFO_KEY);

	// Also remove that pesky cookie!
	document.cookie = 'token=; Path=/; Max-Age=0';
};

const currentPathName = window.location.pathname;

const routeInitialState = {
	user: checkUser(),
	indicator: false,
	template: {
		id: 1,
		name: 'Template 1',
		sections: [
			{
				id: 3,
				icon: 'dot',
				title: 'PREVENTION',
				subsections: [
					{
						id: 31,
						title: 'Training on the Plan',
						content: '<p>more stuff here</p>',
					},
					{
						id: 32,
						title: 'Exercise the Plan',
						content: `<p>doing stuff here</p>`,
					},
				],
			},
		],
		bonusFiles: [],
	},
	templateLoading: true,
	filtered: {
		sections: [],
	},
	shouldScroll: false,
	scrollTarget: null,
	scrollType: null,
	lastSelectedSchoolYear: loadLastStoredValueFor(LAST_SCHOOL_YEAR_KEY),
	lastSelectedSite: loadLastStoredValueFor(LAST_SITE_KEY),
	lastSelectedState: loadLastStoredValueFor(LAST_STATE_KEY),
	drillsDashboardLastTabId: null,
	districtId: null,
	drillsFilterationData: checkFirstResponderBuilding(),
	permissions: {
		buildingPermissions: {},
		buildingHierarchy: {},
		isSysAdmin: false,
	},
	assessmentFilterStateData: {},
	userManagementFiltersData: {
		selectedSitesValue: [],
		selectedRolesValue: [],
		searchUsersValue: '',
		disabledUsersCheck: false,
	},
	permissionsFetched: false,
	isPrepareOpen: !DOCUMENTATION_ROUTES.includes(currentPathName),
	isDocumentationOpen: DOCUMENTATION_ROUTES.includes(currentPathName),
};

const SET_LAST_SELECTED_SCHOOL_YEAR = 'SET_LAST_SELECTED_SCHOOL_YEAR';
export const setLastSelectedSchoolYear = schoolYear => ({
	type: SET_LAST_SELECTED_SCHOOL_YEAR,
	payload: schoolYear,
});

const SET_LAST_SELECTED_SITE = 'SET_LAST_SELECTED_SITE';
export const setLastSelectedSite = site => ({
	type: SET_LAST_SELECTED_SITE,
	payload: site,
});

const SET_LAST_SELECTED_STATE = 'SET_LAST_SELECTED_STATE';
export const setLastSelectedState = states => ({
	type: SET_LAST_SELECTED_STATE,
	payload: states,
});

const SET_DRILLS_DASH_LAST_SELECTED_TAB = 'SET_DRILLS_DASH_LAST_SELECTED_TAB';
export const setDrillsDashLastSelectedTab = tabId => ({
	type: SET_DRILLS_DASH_LAST_SELECTED_TAB,
	payload: tabId,
});

const SET_DRILLS_FILTERATION_DATA = 'SET_DRILLS_FILTERATION_DATA';
export const setDrillsFilterationData = data => ({
	type: SET_DRILLS_FILTERATION_DATA,
	payload: data,
});
const SET_AEESSMENT_FILTER_STATE_DATA = 'SET_AEESSMENT_FILTER_STATE_DATA';
export const setAssessmentFilterStateData = data => ({
	type: SET_AEESSMENT_FILTER_STATE_DATA,
	payload: data,
});

const SET_USER_MANAGEMENT_FILTERS_DATA = 'SET_USER_MANAGEMENT_FILTERS_DATA';
export const setUserManagementFiltersData = data => ({
	type: SET_USER_MANAGEMENT_FILTERS_DATA,
	payload: data,
});

const RESET_STATE = 'RESET_STATE';
export const resetState = () => ({
	type: RESET_STATE,
});

export default function routeReducer(state = routeInitialState, action) {
	switch (action.type) {
		case RESET_STATE:
			resetLocalStorageValues();
			return routeInitialState;

		case SET_LAST_SELECTED_SCHOOL_YEAR:
			localStorage.setItem(LAST_SCHOOL_YEAR_KEY, JSON.stringify(action.payload));
			return { ...state, lastSelectedSchoolYear: action.payload };
		case SET_LAST_SELECTED_SITE:
			localStorage.setItem(LAST_SITE_KEY, JSON.stringify(action.payload));
			return {
				...state,
				lastSelectedSite: action.payload,
			};
		case SET_SELECTED_SITES:
			return {
				...state,
				selectedSites: action.payload,
			};
		case SET_LAST_SELECTED_STATE:
			localStorage.setItem(LAST_STATE_KEY, JSON.stringify(action.payload));
			return { ...state, lastSelectedState: action.payload };
		case LOCATION_CHANGE:
			return { ...state, location: action.payload };
		case SET_DRILLS_DASH_LAST_SELECTED_TAB:
			return { ...state, drillsDashboardLastTabId: action.payload };
		case SET_DRILLS_FILTERATION_DATA:
			return { ...state, drillsFilterationData: action.payload };
		case SET_AEESSMENT_FILTER_STATE_DATA:
			return { ...state, assessmentFilterStateData: action.payload };
		case SET_USER_MANAGEMENT_FILTERS_DATA:
			return {
				...state,
				userManagementFiltersData: {
					...state.userManagementFiltersData,
					...action.payload,
				},
			};
		case SET_USER: {
			const user = {
				jwt: action.payload,
			};
			if (action.payload && action.payload !== '') {
				localStorage.setItem('user', JSON.stringify(user));
				return {
					...state,
					user: action.payload,
				};
			}
			return state;
		}
		case UNSET_USER: {
			const localToken = JSON.parse(localStorage.getItem('user'));
			localToken.jwt = '';
			localStorage.setItem('user', JSON.stringify(localToken));
			return { ...state, user: null };
		}
		case SET_USER_DATA: {
			const loggedUserData = action.payload;
			localStorage.setItem('loggedUserData', JSON.stringify(loggedUserData));
			return {
				...state,
				loggedUserData: action.payload,
			};
		}
		case SET_CURRENT_DISTRICT: {
			localStorage.setItem(CURRENT_DISTRICT_KEY, JSON.stringify(action.payload));
			return {
				...state,
				currentDistrict: action.payload,
			};
		}
		case SET_MASQUERADING_USER_TOKEN: {
			const masqueradingUserToken = {
				jwt: action.payload,
			};
			localStorage.setItem(MASQUERADING_USER_TOKEN, JSON.stringify(masqueradingUserToken));
			return {
				...state,
				MASQUERADING_USER_TOKEN: action.payload,
			};
		}
		case SET_MASQUERADING_USER_DATA: {
			const masqueradingUserData = action.payload;
			localStorage.setItem(MASQUERADING_USER_DATA, JSON.stringify(masqueradingUserData));
			return {
				...state,
				MASQUERADING_USER_DATA: action.payload,
			};
		}
		case UPDATE_SUBSECTION: {
			const { template, filtered } = state;
			template.sections[action.sectionIndex].subsections[action.subsectionIndex].content =
				action.payload;
			filtered.sections[action.sectionIndex].subsections[action.subsectionIndex].content =
				action.payload;
			return { ...state, indicator: false, template, filtered };
		}
		case UPDATE_SECTION_CONTENT: {
			const { template, filtered } = state;
			template.sections[action.sectionIndex].content = action.payload;
			filtered.sections[action.sectionIndex].content = action.payload;
			return { ...state, indicator: false, template, filtered };
		}
		case REORDER_SECTIONS: {
			const { filtered } = state;
			const tempPayload = JSON.parse(JSON.stringify(action.payload)); // remove reference
			const temp = tempPayload.sections.splice(0, filtered.sections.length);

			tempPayload.sections = temp;
			return {
				...state,
				indicator: false,
				template: action.payload,
				filtered: tempPayload,
			};
		}
		case INDICATOR_RED: {
			if (state.indicator === false) {
				return state;
			}
			return { ...state, indicator: false };
		}
		case LOAD_TEMPLATE: {
			return {
				...state,
				template: action.payload,
				filtered: action.filtered,
				templateLoading: false,
			};
		}
		case UPDATE_TEMPLATE_NAME: {
			return {
				...state,
				template: { ...state.template, name: action.payload },
			};
		}
		case UPDATE_SECTION_TITLE: {
			const temp = { ...state.template };
			const tempFiltered = { ...state.filtered };
			temp.sections[action.sectionIndex].title = action.payload;

			const sectionId = temp.sections[action.sectionIndex].id;

			tempFiltered.sections.map(section => {
				if (section.id === sectionId) {
					section.title = action.payload;
				}
				return section;
			});

			return {
				...state,
				indicator: false,
				template: temp,
				filtered: tempFiltered,
			};
		}
		case UPDATE_SUBSECTION_TITLE: {
			const temp = { ...state.template };
			const tempFiltered = { ...state.filtered };

			temp.sections[action.sectionIndex].subsections[action.subsectionIndex].title =
				action.payload;

			const sectionId = temp.sections[action.sectionIndex].id;
			const subSectionId =
				temp.sections[action.sectionIndex].subsections[action.subsectionIndex].id;

			tempFiltered.sections.map(section => {
				if (section.id === sectionId) {
					section.subsections.map(subsection => {
						if (subsection.id === subSectionId) {
							subsection.title = action.payload;
						}
						return subsection;
					});
				}
				return section;
			});

			return {
				...state,
				indicator: false,
				template: temp,
				filtered: tempFiltered,
			};
		}
		case FLIP_REUPLOAD: {
			const { allowReupload } = action;
			const temp = { ...state.template };
			temp.sections[action.sectionIndex].subsections[
				action.subsectionIndex
			].reupload = allowReupload;
			return { ...state, indicator: false, template: temp };
		}
		case ADD_FILE: {
			const temp = { ...state.template };
			temp.sections[action.sectionIndex].subsections[action.subsectionIndex].baseFile =
				action.baseFile;
			return { ...state, indicator: false, template: temp };
		}
		case DELETE_FILE: {
			const temp = { ...state.template };
			temp.sections[action.sectionIndex].subsections[action.subsectionIndex].baseFile = {
				coreTemplate: null,
				name: '',
				size: '',
				id: null,
			};
			temp.sections[action.sectionIndex].subsections[action.subsectionIndex].reupload = false;
			return { ...state, indicator: false, template: temp };
		}
		case DELETE_VALUE_FILE: {
			const temp = { ...state.template };
			temp.sections[action.sectionIndex].subsections[action.subsectionIndex].valueFile = {
				name: '',
				size: '',
				id: null,
			};

			const tempPayload = JSON.parse(JSON.stringify(temp)); // remove reference
			const tmp = tempPayload.sections.splice(0, state.filtered.sections.length);
			tempPayload.sections = tmp;

			return {
				...state,
				indicator: false,
				template: temp,
				filtered: tempPayload,
			};
		}
		case ADD_VALUE_FILE: {
			const temp = { ...state.template };
			temp.sections[action.sectionIndex].subsections[action.subsectionIndex].valueFile =
				action.valueFile;

			const tempPayload = JSON.parse(JSON.stringify(temp)); // remove reference
			const tmp = tempPayload.sections.splice(0, state.filtered.sections.length);
			tempPayload.sections = tmp;
			return {
				...state,
				indicator: false,
				template: temp,
				filtered: tempPayload,
			};
		}

		case ADD_SUBSECTION: {
			const temp = { ...state.template };
			const headers = temp.headers || {};
			if (!headers?.idsStorage) {
				headers.idsStorage =
					action.source == 'plan'
						? idsStorageDefaultFormat.plan
						: action.source == 'site'
						? idsStorageDefaultFormat.site
						: idsStorageDefaultFormat.core;
			}
			let tempPayload = { ...state.filtered };
			let newSubsectionId = headers.idsStorage.subsectionId;
			let checkIfIsClient;
			let subsectionDeletionDetected = true;
			if (action.source == 'plan') {
				checkIfIsClient = subsection => {
					return (subsection.isClient = 1);
				};
			} else if (action.source == 'site') {
				checkIfIsClient = subsection => {
					return (subsection.isClient = 2);
				};
			} else {
				checkIfIsClient = subsection => {
					return !subsection.isClient;
				};
			}

			temp.sections.forEach(section => {
				section.subsections.forEach(item => {
					if (
						parseInt(item.id + 1) > parseInt(newSubsectionId) &&
						checkIfIsClient(item)
					) {
						newSubsectionId = item.id + 1; //GET LAST ID NUM
						subsectionDeletionDetected = false;
					}
				});
			});
			if (subsectionDeletionDetected) {
				newSubsectionId = parseInt(newSubsectionId) + 1;
			}

			const tempSub = {
				id: newSubsectionId,
				title: `Subsection ${temp.sections[action.sectionIndex].subsections.length +
					1} title`,
				content: 'This page intentionally left blank.',
			};
			if (action.source == 'plan') {
				tempSub['isClient'] = 1;
			} else if (action.source == 'site') {
				tempSub['isClient'] = 2;
			}
			headers.idsStorage.subsectionId = newSubsectionId;
			temp.headers = headers;
			temp.sections[action.sectionIndex].subsections.push(tempSub);

			if (action.removeReference) {
				tempPayload = JSON.parse(JSON.stringify(temp)); // remove reference
				const tmp = tempPayload.sections.splice(0, state.filtered.sections.length);
				tempPayload.sections = tmp;
			}

			return {
				...state,
				indicator: false,
				template: temp,
				filtered: tempPayload,
			};
		}
		case ADD_DOCUMENT_UPLOAD: {
			const temp = { ...state.template };
			const headers = temp.headers || {};
			if (!headers?.idsStorage) {
				headers.idsStorage =
					action.source == 'plan'
						? idsStorageDefaultFormat.plan
						: action.source == 'site'
						? idsStorageDefaultFormat.site
						: idsStorageDefaultFormat.core;
			}
			let newSubsectionId = headers.idsStorage.subsectionId;
			let checkIfIsClient;
			let subsectionDeletionDetected = true;
			if (action.source == 'plan') {
				checkIfIsClient = subsection => {
					return (subsection.isClient = 1);
				};
			} else if (action.source == 'site') {
				checkIfIsClient = subsection => {
					return (subsection.isClient = 2);
				};
			} else {
				checkIfIsClient = subsection => {
					return !subsection.isClient;
				};
			}
			temp.sections.forEach(section => {
				section.subsections.forEach(item => {
					if (
						parseInt(item.id + 1) > parseInt(newSubsectionId) &&
						checkIfIsClient(item)
					) {
						newSubsectionId = item.id + 1; //GET LAST ID NUM
						subsectionDeletionDetected = false;
					}
				});
			});
			if (subsectionDeletionDetected) {
				newSubsectionId = parseInt(newSubsectionId) + 1;
			}
			const tempSub = {
				id: newSubsectionId,
				title: `File subsection ${temp.sections[action.sectionIndex].subsections.length +
					1} title`,
				content: '',
				fileSection: true,
				reupload: false,
				baseFile: {
					coreTemplate: null,
					name: '',
					size: '',
					id: null,
				},
				valueFile: {
					name: '',
					size: '',
					id: null,
				},
			};
			headers.idsStorage.subsectionId = newSubsectionId;
			temp.headers = headers;
			temp.sections[action.sectionIndex].subsections.push(tempSub);
			return { ...state, indicator: false, template: temp };
		}

		case ADD_SECTION: {
			const temp = { ...state.template };
			const headers = temp.headers || {};
			if (!headers?.idsStorage) {
				headers.idsStorage =
					action.source == 'plan'
						? idsStorageDefaultFormat.plan
						: action.source == 'site'
						? idsStorageDefaultFormat.site
						: idsStorageDefaultFormat.core;
			}
			const tempFiltered = { ...state.filtered };

			let newSectionId = headers.idsStorage.sectionId;
			let newSubsectionId = headers.idsStorage.subsectionId;
			let checkIfIsClient;
			let sectionDeletionDetected = true;
			let subsectionDeletionDetected = true;

			if (action.source == 'plan') {
				checkIfIsClient = section => {
					return (section.isClient = 1);
				};
			} else if (action.source == 'site') {
				checkIfIsClient = section => {
					return (section.isClient = 2);
				};
			} else {
				checkIfIsClient = section => {
					return !section.isClient;
				};
			}
			if (temp.sections.length > 0) {
				temp.sections.forEach(item => {
					if (parseInt(item.id + 1) > parseInt(newSectionId) && checkIfIsClient(item)) {
						newSectionId = item.id + 1; // GET LAST ID NUM OF SECTION
						sectionDeletionDetected = false;
					}
					item.subsections.forEach(subitem => {
						if (
							parseInt(subitem.id + 1) > parseInt(newSubsectionId) &&
							checkIfIsClient(item)
						) {
							newSubsectionId = subitem.id + 1; // GET LAST ID NUM OF SUBSECTION
							subsectionDeletionDetected = false;
						}
					});
				});
			}
			if (sectionDeletionDetected) {
				newSectionId = parseInt(newSectionId) + 1;
			}
			if (subsectionDeletionDetected) {
				newSubsectionId = parseInt(newSubsectionId) + 1;
			}

			const tempSection = {
				id: newSectionId,
				title: `Section ${temp.sections.length + 1} title`,
				content: 'This page intentionally left blank.',
				subsections: [
					{
						id: newSubsectionId,
						title: 'Subsection 1 title',
						content: 'This page intentionally left blank.',
					},
				],
			};

			if (action.source == 'plan') {
				tempSection['isClient'] = 1;
				tempSection.subsections[0]['isClient'] = 1;
			} else if (action.source == 'site') {
				tempSection['isClient'] = 2;
				tempSection.subsections[0]['isClient'] = 2;
			}
			headers.idsStorage.sectionId = newSectionId;
			headers.idsStorage.subsectionId = newSubsectionId;
			temp.headers = headers;
			temp.sections.push(tempSection);
			tempFiltered.sections.push(tempSection);

			return {
				...state,
				indicator: false,
				template: temp,
				filtered: tempFiltered,
			};
		}
		case REMOVE_SECTION: {
			const temp = [...state.template.sections];
			temp.splice(action.sectionIndex, 1);

			return {
				...state,
				template: { ...state.template, sections: temp },
			};
		}
		case REMOVE_SECTION_NEW: {
			const temp = [...state.template.sections];
			const updatedTemp = temp.filter(item => item.id !== action.sectionId);

			const filterSections = [...state.filtered.sections];
			const updatedFilter = filterSections.filter(item => item.id !== action.sectionId);

			return {
				...state,
				template: { ...state.template, sections: updatedTemp },
				filtered: { ...state.filtered, sections: updatedFilter },
			};
		}
		case REMOVE_SUBSECTION: {
			const temp = [...state.template.sections];
			temp[action.sectionIndex].subsections.splice(action.subsectionIndex, 1);

			return {
				...state,
				template: { ...state.template, sections: temp },
			};
		}
		case REMOVE_SUBSECTION_NEW: {
			const temp = [...state.template.sections];

			const updatedTemp = temp.filter(section => {
				if (section.id === action.sectionId) {
					section.subsections = section.subsections.filter(
						subsection => subsection.id !== action.subsectionId,
					);
				}

				return true;
			});

			const filterSections = [...state.filtered.sections];

			const updatedFilter = filterSections.filter(section => {
				if (section.id === action.sectionId) {
					section.subsections = section.subsections.filter(
						subsection => subsection.id !== action.subsectionId,
					);
				}

				return true;
			});

			return {
				...state,
				template: { ...state.template, sections: updatedTemp },
				filtered: { ...state.filtered, sections: updatedFilter },
			};
		}
		case CLEAR_TEMPLATE_DATA: {
			return {
				...state,
				template: {},
				filtered: {
					sections: [],
				},
				templateLoading: true,
			};
		}
		case NEXT_SECTION: {
			return {
				...state,
				filtered: action.payload,
			};
		}
		case AUTO_SAVE: {
			return { ...state, indicator: true };
		}
		case AUTO_SAVE_INIT: {
			return { ...state, indicator: false };
		}
		case ADD_BONUS_FILES: {
			const temp = { ...state.template };
			if (!temp.bonusFiles) {
				temp.bonusFiles = [];
			}
			temp.bonusFiles.push(action.valueFile);
			return { ...state, template: temp };
		}
		case REMOVE_BONUS_FILE: {
			const temp = { ...state.template };
			const newBonus = temp.bonusFiles.filter(item => item.id !== action.payload.id);
			const newTemp = temp;
			newTemp.bonusFiles = newBonus;
			return { ...state, template: temp };
		}
		case MARK_SUBSECTION_COMPLETE: {
			const temp = { ...state.template };
			temp.sections[action.sectionIndex].subsections[action.subsectionIndex].complete =
				action.payload;

			const tempFiltered = { ...state.filtered };
			tempFiltered.sections[action.sectionIndex].subsections[
				action.subsectionIndex
			].complete = action.payload;

			return {
				...state,
				indicator: false,
				template: temp,
				filtered: tempFiltered,
			};
		}
		case MARK_SECTION_COMPLETE: {
			const temp = { ...state.template };
			temp.sections[action.sectionIndex].complete = action.payload;

			const tempFiltered = { ...state.filtered };
			tempFiltered.sections[action.sectionIndex].complete = action.payload;

			return {
				...state,
				indicator: false,
				template: temp,
				filtered: tempFiltered,
			};
		}
		case SET_SCROLL_MODE: {
			return {
				...state,
				shouldScroll: true,
				scrollTarget: action.scrollTarget,
				scrollType: action.scrollType,
			};
		}
		case UNSET_SCROLL_MODE: {
			return {
				...state,
				shouldScroll: false,
				scrollTarget: null,
				scrollType: null,
			};
		}
		case SET_DISTRICT_ID: {
			return {
				...state,
				districtId: action.districtId,
			};
		}
		case SET_ROOT_PERMISSIONS: {
			return {
				...state,
				districtId: action.districtId,
				permissionsFetched: true,
				permissions: {
					...state.permissions,
					isSysAdmin: action.isSysAdmin,
					buildingPermissions: action.permissions,
					buildingHierarchy: action.buildings,
				},
			};
		}
		case AUTO_SAVE_TIME: {
			const temp = { ...state.template };
			temp.lastUpdatedFormatted = action.payload;
			temp.dateWithTimezone = action.dateWithTimezone;

			return {
				...state,
				template: temp,
				indicator: true,
			};
		}
		case DISTRICT_PLANS_SITE: {
			return {
				...state,
				districtPlanSite: action.payload,
			};
		}
		case SET_DISTRICT_TOKEN: {
			if (action.payload && action.payload !== '') {
				localStorage.setItem(DISTRICT_TOKEN, action.payload);
				return {
					...state,
					DISTRICT_TOKEN: action.payload,
				};
			}
			return state;
		}
		case SET_CALL_LIST_SITES: {
			return {
				...state,
				callListSites: action.payload,
			};
		}
		case SET_CALL_LIST_VIEW_TYPE: {
			return {
				...state,
				callListViewType: action.payload,
			};
		}
		case IS_PREPARE_OPEN: {
			return {
				...state,
				isPrepareOpen: action.payload,
			};
		}
		case IS_DOCUMENTATION_OPEN: {
			return {
				...state,
				isDocumentationOpen: action.payload,
			};
		}
		case MODAL_TO_SHOW: {
			return {
				...state,
				modalToShow: action.payload,
			};
		}
		case RELOAD_FETCHED_LIST: {
			return {
				...state,
				toggleState: action.payload,
			};
		}

		case UPDATE_LOCAL_STORAGE: {
			return {
				...state,
				updateLocalStorageKey: action.payload,
			};
		}

		default:
			return state;
	}
}
