import { DateTime } from 'luxon';

export const parsePHPDateTime = (date, timezoneType, timezone) => {
	if (timezoneType === 1) {
		return DateTime.fromSQL(date).setZone(timezone);
	}
	return DateTime.fromSQL(date, {
		zone: timezone,
	});
};

export const safeDateToLuxon = date => {
	const errorMessage =
		'The date is not a Date (js date), DateTime (luxon date), nor a valid date string please check the function call';

	// handle JS dates
	if (date instanceof Date) {
		return DateTime.fromJSDate(date);
	}

	// handle luxon date
	if (date instanceof DateTime) {
		return date;
	}

	// handle strings
	if (typeof date === 'string') {
		try {
			const parsed = new Date(date);
			return DateTime.fromJSDate(parsed);
		} catch (err) {
			throw new Error(errorMessage);
		}
	}

	// handle date objects from the backend
	if (
		date &&
		Object.hasOwn(date, 'date') &&
		Object.hasOwn(date, 'timezone_type') &&
		Object.hasOwn(date, 'timezone')
	) {
		return parsePHPDateTime(date.date, date.timezoneType, date.timezone);
	}

	// else return error
	throw new Error(errorMessage);
};

// This method formats either a JS or a Luxon date to the default expected en-US local format
// The luxon format flag is an optional parameter in case it's needed
export const formatDate = (date, luxonFormatFlag = DateTime.DATETIME_SHORT) => {
	const safeLuxonDate = safeDateToLuxon(date);
	return (
		safeLuxonDate
			.toLocal()
			// Inspected luxon code, and the flag is just an object following this spec: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
			.toLocaleString({ locale: 'en-US', ...luxonFormatFlag })
	);
};

export const getDateTimePickerRange = startYear => {
	const endYear = startYear + 1;
	// The MIN (start of the school year) date is July 1st.
	const min = new Date(startYear, 6, 1);
	min.setHours(12, 0, 0, 0); // setting the initiall time to be 12PM.
	// The MAX (end of school year) date is June 30th of next year.
	const max = new Date(endYear, 5, 30);
	return { min, max };
};
