import React from 'react';
import { Badge } from 'componentsV2';

export const TableStatusCell = ({ status }) => {
	const DRILL_TYPE_COLORS = {
		Scheduled: 'Primary',
		'In Progress': 'Warning',
		Overdue: 'Error',
		Completed: 'Success',
		Unscheduled: 'Gray',
	};

	if (status !== 'N/A') {
		return <Badge text={status} type={DRILL_TYPE_COLORS[status]} size="lg" />;
	} else {
		return <span>N/A</span>;
	}
};
